import { EventEmitter } from 'events' ;

export const BigBigWork:EventEmitter = new EventEmitter () ;

export const BBWpriceManager = {
    priceManager: undefined
} ;

export const GlobalInitEvent = function () {
    // 显示VIP价格弹出框，异步加载模块
    BigBigWork.on ( `ShowVipBox`, function ( data ) {
        // if ( !window.VipBox ) {
        //     window.VipBox = vipbox ;
        //     window.VipBox.isA = false ;
        //     window.setTimeout ( function () {
        //         if ( data.title ) {
        //             window.VipBox.DialogInstance.$children[0].customTitle = data.title ;
        //         }
        //         if ( data.source ) {
        //             window.VipBox.DialogInstance.$children[0].priceManager.source = data.source ;
        //         }
        //         // 统计打开弹窗的用户信息
        //         window.VipBox.DialogInstance.$children[0].priceManager.analysis () ;
        //         // @ts-ignore
        //         UserInfo.actions.checkUnique (UserInfo).then(res => {
        //             console.log('checkUnique', res)
        //             if (res) {
        //                 window.VipBox.show () ;
        //             }
        //         })
        //         // _self.User.checkUnique ( function ( e ) {
        //         //     if ( e.status == 200 ) {
        //         //         window.VipBox.show () ;
        //         //     }
        //         // } ) ;
        //     }, 100 ) ;
        // } else {
        //     if ( data.title ) {
        //         window.VipBox.DialogInstance.$children[0].customTitle = data.title ;
        //     }
        //     if ( data.source ) {
        //         window.VipBox.DialogInstance.$children[0].priceManager.source = data.source ;
        //     }
        //     // 统计打开弹窗的用户信息
        //     window.VipBox.DialogInstance.$children[0].priceManager.analysis () ;
        //     // @ts-ignore
        //     UserInfo.actions.checkUnique (UserInfo).then(res => {
        //         console.log('checkUnique', res)
        //         if (res) {
        //             window.VipBox.show () ;
        //         }
        //     })
        //     // _self.User.checkUnique ( function ( e ) {
        //     //     if ( e.status == 200 ) {
        //     //         window.VipBox.show () ;
        //     //     }
        //     // } ) ;
        // }
        // // 调用统计
        // BigBigWork.emit ( `Analysis`, { type: `VipShow`, data: data.source } ) ;
    } ) ;
    // 显示邀请弹窗（大）
    BigBigWork.on ( `ShowInviteBig`, function ( data ) {
        // require.ensure ( [], function () {
        //     var InviteBig = require ( `./PageHeader/components/InviteDialogBig.vue` ).default ;
        //     var inviteDialogBig = new Dialog ( {
        //         child: InviteBig,
        //         theme: `invite-big-box`
        //     } ) ;
        //     inviteDialogBig.show () ;
        // }, `vipbox` ) ;
    } ) ;
} ;
