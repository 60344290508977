/* eslint-disable  camelcase */
import { Module, Store } from "vuex" ;
import { getFieldList, getIndustryList, getSiteList, getHotWordList } from "@constants/servlet" ;
import { getToken } from "@scripts/tools" ;
import axios from 'axios' ;
import qs from 'qs' ;

interface FieldItem {
    id: string,
    name: string,
    url: string
}
interface HotWordItem {
    create_at: string,
    id: number,
    idStr: string,
    industry_id: number
    name: unknown
    status: number
    update_at: string
    word: string
}

type FieldList = Array<FieldItem>
type HotWordList = Array<HotWordItem>

interface State {
    fieldList: FieldList,
    isGetedIndustry: boolean,
    industryList: Array<any>,
    currentIndustry: any,
    sameLavelIndustryList:Array<any>,
    siteList: Array<any>,
    isReqSiteList: Boolean,
    hotWordList: HotWordList,
    todoSiteListFn: Array<any>,
    isReqingIndustry: boolean,
    todoIndustryListFn: Array<any>
}

export const Data: Module<State, any> = {
    state: {
        fieldList: [],
        isGetedIndustry: false,
        industryList: [],
        currentIndustry: null,
        sameLavelIndustryList: [],
        siteList: [],
        hotWordList: [],
        isReqSiteList: false,
        todoSiteListFn: [],
        todoIndustryListFn: [],
        isReqingIndustry: false
    },
    mutations: {
        updateFieldList: ( state: State, val: any ) => {
            state.fieldList = val ;
        },
        updateIndustryList: ( state: State, val: any ) => {
            state.isGetedIndustry = true ;
            state.industryList = val
        },
        updateCurrentIndusry: ( state: State, val: any ) => {
            state.currentIndustry = val ;
        },
        updateSameLavelIndustryList: ( state: State, val: any ) => {
            state.sameLavelIndustryList = val ;
        },
        updataSiteList: (state: State, val: any) => {
            state.siteList = val
        },
        updateHotWordList: (state: State, val: any) => {
            state.hotWordList = val
        },
        updateTodoSiteListFn: (state: State, val: any) => {
            state.todoSiteListFn.push(val)
        },
        updateIsReqSiteList: (state: State, val: any) => {
            state.isReqSiteList = val
        },
        clearSiteListTodoFn: (state: State, val: any) => {
            state.todoSiteListFn = []
        },
        updateIsReqingIndustry: ( state: State, val: boolean ) => {
            state.isReqingIndustry = val
        },
        updateTodoIndustry: ( state: State, payload:{ del?: boolean, add?: boolean, fn?: any } ) => {
            if ( payload.add ) {
                state.todoIndustryListFn.push(payload.fn)
            }else {
                state.todoIndustryListFn = []
            }
        }
    },
    actions: {
        /**
         * @description 获取所有领域(兴趣领域弹框中使用)
         * @param context
         * @constructor
         */
        FETCH_FIELD_LIST: async ( context ) => {
            if ( context.state.fieldList.length !== 0 ) return context.state.fieldList ;
            try {
                const res = await axios ( {
                    url: getFieldList
                } ) ;
                const { data } = res as any ;
                if ( data.status === 200 ) {
                    context.commit ( `updateFieldList`, data.data ) ;
                    return data.data ;
                } else {
                    console.dir ( `检查FETCH_ALL_FIELD_LIST`, res ) ;
                    return [] ;
                }
            } catch ( err ) {
                console.dir ( `检查FETCH_ALL_FIELD_LIST`, err ) ;
            }
        },
        /**
         * @description 获取所有行业分类
         */
        FETCH_INDUSTRY_LIST: async ( context, payLoad = {} ) => {
            if ( context.state.isGetedIndustry ) return context.state.industryList ;
            if (context.state.isReqingIndustry && context.state.fieldList.length === 0) {
                return new Promise(resolve => {
                    context.commit('updateTodoIndustry', { add: true, fn: resolve })
                })
            }
            context.commit('updateIsReqingIndustry', true)
            class Industry {
                public id: number;
                public name: string;
                public description: string;
                constructor ( options ) {
                    /**
                     * @description 行业ID
                     * @type {number}
                     */
                    this.id = options.id || 0 ;
                    /**
                     * @description 行业名字
                     * @type {string}
                     */
                    this.name = options.industry_name || `` ;
                    /**
                     * @description 行业描述
                     * @type {string}
                     */
                    this.description = options.description || `` ;
                }
            }
            let sameLavelIndustryList = [] ;
            const industryList = [] ;
            return axios ( {
                method: `post`,
                url: getIndustryList,
                data: qs.stringify({
                    user_token: await getToken()
                }),
                headers: {
                    "Content-Type": 'application/x-www-form-urlencoded'
                }
            } )
                .then ( data => {
                    const res = data.data ;
                    let allIndustryList = [] ;
                    sameLavelIndustryList = [] ;
                    // 生成行业列表
                    for ( let i = 0 ; i < res.data.length ; i++ ) {
                        const list = [] ;
                        sameLavelIndustryList = [...sameLavelIndustryList, res.data[i].industry_name] ;
                        for ( let j = 0 ; j < res.data[i].list.length ; j++ ) {
                            sameLavelIndustryList = [...sameLavelIndustryList, res.data[i].list[j].industry_name] ;
                            list.push ( new Industry ( res.data[i].list[j] ) ) ;
                        }
                        const _industry = { id: res.data[i].id, industry_name: res.data[i].industry_name, list: list } ;
                        industryList.push ( _industry ) ;
                        allIndustryList = [...allIndustryList, ...list] ;
                    }
                    // 生成一个“全部”行业
                    sameLavelIndustryList = Array.from(new Set ( sameLavelIndustryList )) ;
                    context.commit ( `updateSameLavelIndustryList`, sameLavelIndustryList ) ;
                    var allIndustry = { id: -1, industry_name: `全部`, name: `全部`, list: allIndustryList } ;
                    industryList.push ( allIndustry ) ;
                    context.commit ( `updateIndustryList`, industryList ) ;
                    /** 等待队列执行清空 */
                    context.commit('updateIsReqingIndustry', false)
                    if (context.state.todoIndustryListFn.length) {
                        context.state.todoIndustryListFn.forEach(fn => fn())
                        context.commit('updateTodoIndustry', { del: true })
                    }
                    return industryList ;
                } ).catch ( function ( e ) {
                    console.dir ( `检查FETCH_INDUSTRY_LIST` ) ;
                } ) ;
        },
        /**
         * @description 根据id获取分类详情
         */
        GET_INDUSTRY_BY_ID: async ( context, payLoad:{id: string} ) => {
            // 从所有分类里面找行业
            await context.dispatch('FETCH_INDUSTRY_LIST')
            const industryId = Number(payLoad.id)
            let result = null ;
            for ( const item of context.state.industryList ) {
                if ( item.id == -1 ) {
                    // 所有分类
                    for ( const ins of item.list ) {
                        // eslint-disable-next-line no-undef,no-return-assign
                        if ( ins.id === industryId ) return result = ins ;
                    }
                }
            }
            // if ( !result ) {
            //     // 所有分类找不到的话找感兴趣领域
            //     for ( const ins of payLoad.UserInfo.likeFieldList ) {
            //         // eslint-disable-next-line no-undef,no-return-assign
            //         if ( ins.id === payLoad.id ) return result = ins ;
            //     }
            // }
            // 再找不到，就取第一个行业
            return result || this.industryList[this.industryList.length - 1] ;
        },
        /**
         * @description 根据name获取分类详情
         */
        GET_INDUSTRY_BY_NAME: async ( context, payLoad = {} ) => {
            let allIndustry = [] ;
            context.state.industryList.forEach ( item => {
                allIndustry = [...allIndustry, ...item.list] ;
            } ) ;
            const findItem = allIndustry.find ( item => {
                return item.name === payLoad.industryName ;
            } ) ;
            return findItem || { name: `全部`, id: -1, industry_name: `全部` } ;
        },
        /**
         * @description 获取所有站点
         */
        GET_SITE_LIST: async (context, payLoad = {}) => {
            if (context.state.isReqSiteList && !context.state.siteList.length) {
                // 已发出请求 && 请求未结束 需等待
                return new Promise(function (resolve) {
                    context.commit('updateTodoSiteListFn', resolve)
                })
            }
            context.commit('updateIsReqSiteList', true)
            if (context.state.siteList.length) return context.state.siteList
            const siteVersion = 180319
            const sitesList = []
            let sitesInfo = localStorage.getItem("bbw-sitesInfo") as any;
            if (sitesInfo && sitesInfo.time) {
                var time = parseInt(sitesInfo.time);
                var today = new Date().getTime();
                // 12小时内缓存过网站信息的，不再去服务器查找网站信息
                if (today - time <= 1000 * 60 * 60 * 12) {
                    sitesInfo = JSON.parse(sitesInfo);
                    if (sitesInfo.version === siteVersion) {
                        for (var i in sitesInfo.data) {
                            sitesList.push(sitesInfo.data[i]);
                        }
                        context.commit('updataSiteList', sitesList)
                        return sitesList;
                    }
                }
            }
            // 没有站点信息或站点信息版本不符就去服务器上获取，并存储在本地缓存
            const res = await axios({
                url: getSiteList,
                params: {
                    refresh: 1,
                    test: 1
                }
            })
            context.commit('updateIsReqSiteList', false)
            const data = res.data
            if (data.siteName) {
                data.siteName.push({
                    id: 0,
                    name: "Pinterest",
                    icon: 'pinterest.com.jpg'
                });
                for (const i in data.siteName) {
                    if (data.siteName[i].id !== 160) {
                        sitesList.push(data.siteName[i]);
                    }
                }
                var sitesData = {
                    version: siteVersion,
                    time: new Date().getTime(),
                    data: sitesList
                };
                localStorage.setItem("bbw-sitesInfo", JSON.stringify(sitesData));
                context.commit('updataSiteList', sitesList)
                // 执行等待函数
                if ( context.state.todoSiteListFn.length ) {
                    context.state.todoSiteListFn.forEach(itemFn => {
                        itemFn(sitesList)
                    })
                    context.commit('clearSiteListTodoFn')
                }
                return sitesList
            }
        },
        /**
         * @description 获取热门搜索词
         */
        GET_HOT_WORD_LIST: async (context) => {
            if ( context.state.hotWordList.length ) return context.state.hotWordList
            return axios({
                url: getHotWordList,
                params: {
                    type: 2,
                    num: 6
                }
            }).then(res => {
                if (res.data.code === 200) {
                    const hotWordList = res.data.hotWordList;
                    context.commit('updateHotWordList', hotWordList)
                    return hotWordList
                }
            }).catch(res => console.log(res))
        }
    }
} ;
export const CreatedData = ( store: Store<unknown> ) => {
    if ( store.hasModule ( `Data` ) ) return ;
    store.registerModule ( `Data`, Data ) ;
} ;
