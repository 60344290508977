<template>
    <div id="app">
        <keep-alive >
            <router-view></router-view>
        </keep-alive>
    </div>
</template>
<script lang=ts>
export default {
  name: 'entry',
  components: {  },
  methods: {
  },
  async mounted() {

  },
  computed: {

  }
}
</script>
