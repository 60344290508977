import Vue from 'vue' ;
import Vuex from 'vuex' ;
import {
    StorePinliteUser,
    StoreRedEnvelope,
    StoreDesigner,
    StoreMessage,
    StoreUserInfo,
    StoreFavBoard,
    StoreData,
    StoreSystem
} from "bbw-common" ;
import Bowser from "bowser" ;

Vue.use ( Vuex ) ;

export type PinliteState = {
    title: string,
    server: boolean,
    clientWidth: number,
    debug: unknown,
    token: string,
    cache: {},
    isIE:boolean
}

export default function CreateStore () {
    return new Vuex.Store<PinliteState> ( {
        state: {
            title: `大作`,
            /** 是否处于服务端 */
            server: true,
            /** 屏幕宽度 */
            clientWidth: 1200,
            /** debug信息存放处 */
            debug: {},
            /** 用户请求带来的token */
            token: ``,
            /** 用于服务器和客户端共享的缓存 */
            cache: {},
            isIE: false,
        },
        mutations: {
            setTitle: function ( state, val ) {
                state.title = val ;
            },
            setDebug: function ( state, data ) {
                const { key, value } = data ;
                state.debug[key] = value ;
            },
            setIsClient: function ( state ) {
                state.server = false ;
            },
            setClientWidth: function ( state, val ) {
                state.clientWidth = val ;
            },
            setToken: function ( state, val ) {
                state.token = val ;
            },
            setCache: function ( state, data ) {
                const { key, value } = data ;
                state.cache[key] = value ;
            },
            checkIE: function ( state, data ) {
                const browser = Bowser.getParser ( window.navigator.userAgent ) ;
                state.isIE = browser.getBrowserName ( true ) === `internet explorer` ;
            }
        },
        actions: {},
        getters: {
            masonryWidth: ( state ) => {
                const rest = ( state.clientWidth + 16 ) % ( 236 + 16 ) ;
                return ( state.clientWidth ) - rest ;
            },
            token: ( state ) => {
                return state.token ;
            }
        },
        modules: {
            UserInfo: StoreUserInfo.UserInfo
        }
    } ) ;
}
