import VueRouter from 'vue-router' ;
import Vue from 'vue' ;
import path from "path";

if( process.env.VUE_ENV === 'server') {
    // 获取环境变量
    require ( `dotenv` ).config ( { path: path.resolve ( __dirname, `../../../.project.env` ) } ) ;
}

Vue.use ( VueRouter ) ;

const PageIndex = () => import ( /* webpackChunkName:"page-index" */ `../src/pages/index/index.vue` ) ;
const Page404 = () => import ( /* webpackChunkName:"page-404" */ `../src/pages/404/404.vue` ) ;
const PagePaymentcode = () => import ( /* webpackChunkName:"page-generate-paymentcode" */ `../src/pages/generate-paymentcode/generate-paymentcode.vue` ) ;
export default function CreateRouter () {
    const Router = new VueRouter ( {
        mode: `history`,
        fallback: false,
        scrollBehavior: ( to, from, savedPosition ) => {
            if ( savedPosition && to.meta.keepAlive ) {
                return savedPosition ;
            }
            return { x: 0, y: 0 } ;
        },
        routes: [
            {
                path: `/`,
                component: PageIndex
            },
            {
                path: `/generate-paymentcode`,
                component: PagePaymentcode
            },
            {
                path: `/404`,
                component: Page404
            },
            {
                path: `*`,
                component: Page404
            }
        ]
    } ) ;
    return Router ;
}
// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
