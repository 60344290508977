import axios from 'axios' ;
import { toRelativeProtocol, getToken } from '@scripts/tools' ;
import { shareRecord, shareImageUpload, getMiniQR } from "@constants/servlet" ;
import qs from 'qs' ;
/**
 * @class
 * @description 分享图片
 * @author xinlu.qiu
 * @param {string} type 分享类型
 * @param {object} [options] 选项，可以设置成员的值
 */
export const Share = function ( type, options ) {
    // 设置选项默认值
    /**
   * @type {string}
   * @description 分享地址
   */
    this.url = encodeURIComponent ( options.url || window.location.href ) ;
    /**
   * @description 分享标题
   * @type {string}
   */
    this.title = encodeURIComponent ( options.title || document.title ) ;
    /**
   * @description 分享描述
   * @type {string}
   */
    this.desc = encodeURIComponent ( options.desc || `` ) ;
    /**
   * @description 简要描述
   */
    this.summary = encodeURIComponent ( options.summary || `` ) ;
    /**
   * @description 分享图片
   */
    this.image = options.image || document.getElementsByTagName ( `img` )[0].src ;
    /**
   * @description 分享链接打开方式
   * @type {string}
   */
    this.target = options.target || `_blank` ;
    /**
   * @description 二维码宽度
   * @type {number}
   */
    this.qrcodeTitle = options.qrcodeTitle || 0 ;
    /**
   * @description 二维码高度
   * @type {number}
   */
    this.qrcodeWidth = options.qrcodeWidth || 100 ;
    /**
   * @description 二维码高度
   * @type {number}
   */
    this.qrcodeHeight = options.qrcodeHeight || 100 ;
    /**
   * @description 分享类型 (qq|weibo|huaban|qzone)
   * @type {string}
   */
    this.type = type ;
    /**
   * @description 分享网站(没有的话代表是搜索引擎)
   * @type {string}
   */
    this.path = options.path || `` ;
    /**
   * @description 是否是图片分享，如果是的话就调用handleImageUrl取得分享用图片地址
   */
    this.imageShare = options.imageShare || false ;
} ;
Share.prototype = {
    /**
   * @description 取得图片的分享用图片地址
   */
    handleImageUrl: function ( callback ) {
        var _self = this ;
        // 搜索引擎不用上传图片
        if ( !this.path ) {
            callback && callback ( _self.image ) ;
            return ;
        }
        // metasearch需要上传图片用于分享
        var type = this.type === `huaban` ? 1 : 0 ;
        axios ( {
            method: `post`,
            url: shareImageUpload,
            data: qs.stringify ( {
                url: _self.image,
                path: this.path,
                shareType: type
            } ),
            headers: {
                "Content-Type": `application/json; charset=utf-8`
            }
        } ).then ( res => {
            const result = res.data ;
            if ( result ) {
                _self.image = decodeURIComponent ( result.url ) ;
            }
            callback && callback ( result ) ;
        } ) ;
    },
    /**
   * @description 分享开始（非微信）
   */
    share: function () {
        var _self = this ;
        if ( this.imageShare ) {
            this.handleImageUrl ( function () {
                _self.handleShare () ;
            } ) ;
        } else {
            _self.handleShare () ;
        }
    },
    /**
   * @description 非微信的分享处理
   * @return {*}
   */
    handleShare: function () {
        var _self = this ;
        var shareUrl ;
        var showType ;
        switch ( _self.type ) {
        case `qq`:
            shareUrl = `http://connect.qq.com/widget/shareqq/index.html?url=` +
          _self.url + `&title=` + _self.title + `&pics=` + _self.image + `&desc=` + _self.desc + `&summary=` + _self.summary ;
            showType = 0 ;
            break ;
        case `weibo`:
            shareUrl = `http://v.t.sina.com.cn/share/share.php?url=` +
          _self.url + `&title=` + _self.title + `&content=utf8&pic=` +
          _self.image + `&appkey=1274725900&ralateUid=6041261970` ;
            showType = 1 ;
            break ;
        case `huaban`:
            shareUrl = `http://huabanpro.com/bookmarklet/?url=` +
          _self.url + `&title=` + _self.title + `&media=` + _self.image ;
            showType = 3 ;
            break ;
        case `copy`:
            // eslint-disable-next-line no-case-declarations
            const input = document.createElement ( `input` ) ;
            document.body.appendChild ( input ) ;
            input.setAttribute ( `value`, decodeURIComponent ( _self.url ) ) ;
            input.select () ;
            if ( document.execCommand ( `copy` ) ) {
                document.execCommand ( `copy` ) ;
                console.log ( `复制成功` ) ;
            }
            document.body.removeChild ( input ) ;
            break ;
        case `qzone`:
            shareUrl = `http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=` +
          _self.url + `&title=` + _self.title + `&pics=` + _self.image + `&summary=` + _self.summary ;
            showType = 2 ;
            break ;
        default :
            return false ;
        }
        if ( _self.type === `copy` ) {
            return ;
        }
        // 在新开的窗口中打开分享链接
        var newTab = window.open ( shareUrl ) ;
        // axios ( {
        //     url: shareUrl
        // } ).then ( res => {
        //     // const data = res.data ;
        //     // if ( data ) {
        //     //     newTab.location.href = shareUrl ;
        //     // }
        //     newTab.location.href = shareUrl ;
        // } ) ;
        return shareUrl || false ;
    },
    /**
   * @description 分享到微信
   * @callback
   */
    shareQrcode: function ( callback ) {
        var _self = this ;
        var dom = document.createElement ( `div` ) ;
        // @ts-ignore
        import ( `bbw-qrcode` ).then ( module => {
            const QRCode = module.default
            var qrcode = new QRCode ( dom, {
                text: decodeURIComponent ( _self.url ),
                width: _self.qrcodeWidth,
                height: _self.qrcodeHeight,
                colorDark: `#000000`,
                colorLight: `#ffffff`,
                correctLevel: QRCode.CorrectLevel.M
            } ) ;
            callback && callback ( dom, qrcode ) ;
        })
    },
    /**
   * @description 取得微信小程序二维码
   * @callback
   */
    getWXAPPQrcode: function ( params, callback ) {
        let acccessUrl = `` ;
        switch ( params.page ) {
        case `tupian`:
            acccessUrl = `/pages/search/image-detail?img_id=${params.iid}` ;
            break ;
        case `boardList`:
            acccessUrl = `/pages/photocollection/photo-unfold?id=${params.bid || ``}$otherid=${params.uid}` ;
            break ;
        case `homepage`:
            acccessUrl = `/pages/other/other-center?uid=${params.uid}` ;
            break ;
        default:
            break ;
        }
        axios ( {
            url: getMiniQR,
            params: {
                url: acccessUrl,
                uuid: params.uuid
            }
        } ).then ( e => {
            const res = e.data ;
            if ( res.status === 200 ) {
                res.miniQR = toRelativeProtocol ( res.miniQR ) ;
                callback && callback ( res ) ;
            } else {
                console.log ( res.status + `获取错误，无法取得小程序分享二维码` ) ;
            }
        } ).catch ( err => {
            console.log ( `获取错误，无法取得小程序分享二维码`, err ) ;
        } ) ;
    },
    /**
   * @description 分享统计
   * @param {string | number} id 搜索引擎图片Id
   */
    shareStatistics: async function ( id ) {
        axios ( {
            url: shareRecord,
            params: {
                user_token: await getToken (),
                img_id: id
            }
        } ) ;
    }
} ;
// export Share ;
