import { isDev } from "@scripts/utils" ;
/** 主要服务器 */
export const ServerMainNode = `//s.bigbigwork.com` ;
/** 认证设计师地址 */
export const ServerDesigner = isDev () ? `//designer-test.bigbigwork.com` : `//designer.bigbigwork.com` ;
/** 翻译服务器 */
export const ServerTranslateNode = isDev () ? [`//word.bigbigwork.com/kw.htm`] : [`//word.bigbigwork.com/kw.htm`] ;
/** 海外搜索服务器 */
export const ServerMainSearchNode = `//sh.bigbigwork.com` ;
/** 国内搜索服务器 */
export const ServerMainSearchNodeInner = `//sc.bigbigwork.com` ;
/** 网站图标服务器地址 **/
export const ServerIconNode = `${process.env.CDN_DZ_PATH}/icon/` ;
/** 主页 */
export const ServerHomePage = `//www.bigbigwork.com` ;
/** 付费服务器 */
export const ServerPayServer = isDev () ? `//pay-test.bigbigwork.com` : `//pay.bigbigwork.com` ;
/** https付费服务器 */
export const ServersPayServer = isDev () ? `https://pay-test.bigbigwork.com` : `https://pay.bigbigwork.com` ;
/** 付费服务器 */
export const ServerPayServerNew = isDev () ? `//payl-test.bigbigwork.com` : `//payl.bigbigwork.com` ;
/** 智能聚合搜索 */
export const ServerMetaSearch = isDev () ? `https://p-test.bigbigwork.com` : `https://p.bigbigwork.com` ;
/** 智能聚合接口 */
export const ServerPService = isDev () ? `//p-test.bigbigwork.com` : `//p.bigbigwork.com` ;
/** 新统计接口 */
export const ServerStatistics = isDev () ? `//stats-test.bigbigwork.com` : `//stats.bigbigwork.com` ;
/** www */
export const ServerWww = isDev () ? `https://www-test.bigbigwork.com` : `https://www.bigbigwork.com` ;
export const ServerWww2 = __DEV__ ? `https://www-test2.bigbigwork.com` : `https://www2.bigbigwork.com` ;
/** 团队版服务器 */
export const ServerTeam = isDev() ? `https://team-test.bigbigwork.com` : `https://team.bigbigwork.com`;
/** AI扩大图 */
export const ServerKuotu = isDev() ? `https://www-test.bigbigwork.com` : `https://kuotu.bigbigwork.com`;
export const ServerPrizes = __DEV__ ? `http://www-test.prizes.design` : `https://www.prizes.design` ;
/** www搜索服务器 **/
export const ServerImageSearch = isDev () ? `//www-test.bigbigwork.com` : `//www.bigbigwork.com` ;
/** M站服务器 */
export const ServerMobileServer = isDev () ? `//m-test.bigbigwork.com` : `//m.bigbigwork.com` ;
export const ServerMobileServer1 = isDev () ? `https://m-test.bigbigwork.com` : `https://m.bigbigwork.com` ;
/** 图片集服务器 **/
export const ServerBoardServer = isDev () ? `//board-test.bigbigwork.com` : `//board.bigbigwork.com` ;
/** 新版图片集服务器 **/
export const ServerBoardServer2 = isDev () ? `//pb-test.bigbigwork.com` : `//pb.bigbigwork.com` ;
/** 鉴黄服务域名 */
export const ServerBoardServerI = __DEV__ ? `//board-i-test.bigbigwork.com` : `//board-i.bigbigwork.com` ;
/** 统计 */
export const ServerAnalysis = `//rz.bigbigwork.com:9200` ;
/** 名片服务器 */
export const ServerBigImage = isDev () ? `//stats-test.bigbigwork.com` : `//stats.bigbigwork.com` ;
/** 通知服务器 */
export const ServerNoticeServer = isDev () ? `//notice-test.bigbigwork.com` : `//notice.bigbigwork.com` ;
/** PinLite */
export const ServerPinLite = isDev () ? `//www-test.${process.env.PINLITE_DOMAIN}` : `//www.${process.env.PINLITE_DOMAIN}` ;
/** PinLiteCC */
export const ServerPinliteccSearch = isDev () ? `//www-test.pinlite.cc` : `//www.pinlite.cc` ;
/** 大兔接口 */
export const ServerRabbitApi = isDev () ? `//rabbitapi-test.bigbigwork.com` : `//rabbitapi.bigbigwork.com` ;
/** 大兔前端 */
export const ServerRabbit = isDev () ? `//rabbit-test.bigbigwork.com` : `//rabbit.bigbigwork.com` ;
/** pinlite接口 */
export const ServerPinliteService = `//pinlite.bigbigwork.com` ;
/** pinlite 登录注册接口 */
export const ServerPinliteLogin = isDev () ? `https://p-test.bigbigwork.com`: `//pinlite-mp.bigbigwork.com` ;
/** pinliteH5服务器 */
export const ServerPinliteMobile = isDev () ? `//m-test.${process.env.PINLITE_DOMAIN}` : `//m.${process.env.PINLITE_DOMAIN}` ;
/** pinlite.net H5服务器 */
export const ServerPinliteNetMobile = isDev () ? `//m-test.pinlite.net` : `//m.pinlite.net` ;
/** pinlite.cc H5服务器 */
export const ServerPinliteCCMobile = isDev () ? `//m-test.pinlite.cc` : `//m.pinlite.cc` ;
/** 推荐词服务器 */
export const ServerRecommendWord = isDev () ? `//sw-test.bigbigwork.com` : `//sw.bigbigwork.com` ;
export const ServerWiki = `http://wiki.bigbigwork.com` ;
/** 返利服务器 */
export const ServerFanli = isDev () ? `//fl-test.bigbigwork.com` : `//fl.bigbigwork.com` ;
/** 返利后端接口 */
export const ServerFanliAPI = isDev () ? `//flhd-test.bigbigwork.com` : `//flhd.bigbigwork.com` ;
/** 压缩图 */
export const ServerYasuotu = isDev () ? `https://yasuotu-test.bigbigwork.com` : `https://yasuotu.bigbigwork.com` ;
/** ai绘画 */
export const ServerAiPainting = isDev () ? `https://www-test.bigbigai.com` : `https://www.bigbigai.com` ;
/** ai绘画接口 */
export const ServerAiPaintingApi = isDev () ? `https://painting-test.bigbigai.com` : `https://painting.bigbigai.com` ;
/** ai绘画接口 */
export const ServerPServiceAI = isDev () ? `https://p-test.bigbigai.com` : `https://p.bigbigai.com` ;
/** M站服务器 */
export const ServerMobileAIServer = isDev () ? `//m-test.bigbigai.com` : `//m.bigbigai.com` ;
export const ServerMobileAIServer1 = isDev () ? `https://m-test.bigbigai.com` : `https://m.bigbigai.com` ;
/** 合法的pinlite域名 */
export const pinliteDomainList = [`pinlite.me`, `pinlite.cc`] ;
/** 检测域名是否是合法的pinlite域名,防止被攻击 */
export const isPinliteDomain = ( domain:string ):boolean => {
    if ( !domain ) return false ;
    const domainList = [`pinlite.me`, `pinlite.cc`, `pinlite.net`];
    return domainList.some ( val => domain.match ( val ) ) ;
} ;
