import assign from "lodash-es/assign" ;
import trim from "lodash-es/trim" ;
import axios from "axios" ;
import {ServerMetaSearch, ServerPayServer, ServerPService, ServerTeam, ServerWww} from "@constants/servers" ;
import { BigBigWork } from "./BigBigWork" ;
import {
    NetError,
    UnknownError,
    getQueryString,
    toRelativeProtocol,
    getToken,
    getDomain,
    getDeviceFingerprint,
    getUrlParam, jumpPage
} from "@scripts/tools" ;
import Cookies from "js-cookie" ;
import URLParse from "url-parse" ;
import { isDev } from "@scripts/utils" ;
import {
    PageForget,
    PageLogin,
    PageLoginOut,
    PageNewRegister, PagePinliteLogin, PagePinliteRegister,
    PageRegister,
    PageRegisterSuccess, PageSearchIndex,
    PageMobileWeixinAuth, PageMobileLoginPhone, PageMobileForgetnew, PageMobileRegisterPhone, PageSearchAuth
} from "@constants/pages" ;
import Router from "vue-router" ;
import { stringify } from "querystring" ;
import { checkIsIncognito, CreateCrossDomain, cookieSet } from '@scripts/./CrossDomain' ;
import {teamInviteDetail} from "@constants/servlet";

/** 电话号码错误 */
export class PhoneError extends Error {
    name:string = `PhoneError`;
}
/** 密码错误 */
export class PasswordError extends Error {
    name:string = `PasswordError`;
}
/** 图形验证码错误 */
export class ImgCodeError extends Error {
    name:string = `ImgCodeError`;
}
/** 短信验证码错误 */
export class VCodeError extends Error {
    name:string = `VCodeError`;
}
/** 邀请码错误 */
export class InviteError extends Error {
    name:string = `InviteError`;
}
/** 账号合并错误 */
export class MergeError extends Error {
    name:string = `MergeError`;
}

/** 验证错误文本 */
export interface AuthErrors {
    checkNoPhone: string,
    checkInvalidPhone: string,
    checkNoImgCode: string,
    checkInvalidImgCode: string,
    checkNoPassword: string,
    checkInvalidPassword: string,
    checkNoVCode: string,
    checkInvalidVCode: string,
    checkExpireVCode: string,
    checkErrorVCode: string,
    checkNoInvite: string,
    checkInvalidInviteCode: string,
    checkExpireInvite: string,
    checkErrorInvite: string,
    registerErrorVCode: string,
    registerErrorInvite: string,
    registerError: string,
    sendVCodeBusy: string,
    sendVCodeHasAccount: string,
    sendVCodeHasNoAccount: string,
    sendVCodeErrorPhone: string,
    /** 一小时请求超过五次 */
    sendVCodeUserBusy1: string,
    /** 一分钟之内请求两次 */
    sendVCodeUserBusy2: string,
    /** 当天请求上限 */
    sendVCodeUserBusy3: string,
    sendVCodeError: string,
    resetPasswordError:string,
    checkCookieFailed: string,
    netError: string,
    loginError: string,
    undefinedError: string,
    sendVCodeNoCode: string,
    unlogin: string,
}
/** 默认注册登录错误文本 */
export const AuthErrorText:AuthErrors = {
    checkNoPhone: `请输入手机号码`,
    checkInvalidPhone: `手机号码格式有误`,

    checkNoImgCode: `请输入图片验证码`,
    checkInvalidImgCode: `验证码格式有误`,

    checkNoPassword: `请输入密码`,
    checkInvalidPassword: `密码格式有误`,

    checkNoVCode: `请输入验证码`,
    checkInvalidVCode: `验证码格式有误`,
    checkExpireVCode: `验证码过期`,
    checkErrorVCode: `验证码错误`,

    checkNoInvite: `请输入正确的邀请码`,
    checkInvalidInviteCode: `邀请码格式有误`,
    checkExpireInvite: `输入的邀请码已失效`,
    checkErrorInvite: `个人邀请码不存在`,

    registerErrorVCode: `验证码错误或已过期`,
    registerErrorInvite: `邀请码不存在`,
    registerError: `注册失败`,

    sendVCodeBusy: `操作频繁，稍后再试！`,
    sendVCodeHasAccount: `该手机号已经被注册`,
    sendVCodeHasNoAccount: `该账号未注册`,
    sendVCodeErrorPhone: `手机错误`,
    sendVCodeUserBusy1: `操作频繁,请一小时后再试`,
    sendVCodeUserBusy2: `操作频繁,请一小时后再试`,
    sendVCodeUserBusy3: `操作频繁,请明天再试`,
    sendVCodeError: `未知错误,请稍后再试`,
    sendVCodeNoCode: `未获取验证码`,

    resetPasswordError: `重置密码失败！`,
    netError: `网络错误,请稍后再试`,

    checkCookieFailed: `页面Cookie未开启`,
    loginError: `手机号或密码有误`,
    undefinedError: `未知错误`,
    unlogin: `请登录后操作`
} ;

/** 验证电话号码格式正确性 */
export const AuthCheckPhone = ( phoneNumber:string, errorTexts:AuthErrors = AuthErrorText ):boolean => {
    phoneNumber = trim ( phoneNumber ) ;
    if ( phoneNumber === `` || !phoneNumber ) {
        throw new PhoneError ( errorTexts.checkNoPhone ) ;
    }
    const regExp = new RegExp ( /^[1|2|3]([0-9]{1}[0-9]{1})[0-9]{8}$/ ) ;
    if ( phoneNumber.match ( regExp ) ) {
        return true ;
    } else {
        throw new PhoneError ( errorTexts.checkInvalidPhone ) ;
    }
} ;
/** 验证时图片验证码格式正确性 */
export const AuthCheckImgCode = ( imgCode:string, errorTexts:AuthErrors = AuthErrorText ):boolean => {
    imgCode = trim ( imgCode ) ;
    if ( imgCode === `` || !imgCode.match ( /^\w+$/ ) ) {
        throw new ImgCodeError ( errorTexts.checkNoImgCode ) ;
    }
    return true ;
} ;

/** 验证密码格式正确性 */
export const AuthCheckPassword = ( password:string, errorTexts:AuthErrors = AuthErrorText ):boolean => {
    password = trim ( password ) ;
    if ( password === `` ) {
        throw new PasswordError ( errorTexts.checkNoPassword ) ;
    }
    return true ;
} ;

/** 验证密码格式正确性（新注册用，6~16位大小写字母及数字） */
export const AuthCheckPasswordRegister = ( password:string, errorTexts:AuthErrors = AuthErrorText ):boolean => {
    password = trim ( password ) ;
    if ( password === `` ) {
        throw new PasswordError ( errorTexts.checkNoPassword ) ;
    } else if ( !password.match ( /^([a-z]|[A-Z]|[0-9]){6,16}$/ ) ) {
        throw new PasswordError ( errorTexts.checkInvalidPassword ) ;
    }
    return true ;
} ;

/** 验证短信验证码格式正确性 */
export const AuthCheckVCode = ( vCode:string, errorTexts:AuthErrors = AuthErrorText ):boolean => {
    vCode = trim ( vCode ) ;
    if ( vCode === `` ) {
        throw new VCodeError ( errorTexts.checkNoVCode ) ;
    }
    return true ;
} ;

/** 服务端验证短信验证码正确性 */
export const AuthCheckVCodeFromServer = async ( phone:string, vCode:string, errorTexts:AuthErrors = AuthErrorText ):Promise<[Error, boolean]> => {
    return axios ( {
        url: ServerPService + `/register-validate-code.htm`,
        method: `POST`,
        data: stringify ( {
            phone,
            code: vCode
        } )
    } ).then ( res => {
        if ( res.status === 200 ) {
            const code = res.data.code.toString () ;
            switch ( code ) {
            case `3`:
                throw new VCodeError ( errorTexts.checkExpireVCode ) ;
            case `1`:
            case `2`:
                throw new VCodeError ( errorTexts.checkErrorVCode ) ;
            case `4`:
                return [null, true] as [Error, boolean] ;
            }
        } else {
            throw new NetError ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;

/**
 * 注册账号
 * @param phone 手机号码
 * @param name 用户名
 * @param password 密码
 * @param code 短信验证码
 * @param errorTexts 错误代码
 * @param registerPath 注册路径
 * @param invitePhone 邀请人手机号码
 * @param state 是否邀请注册，验证邀请码时返回（0或1）
 * @param huahuaState 是否邀请注册，验证邀请码时返回（0或1）
 * @param type
 * @param userSource
 * @param inviteCode 邀请码
 * @constructor
 */
export const AuthRegister = async (
    phone:string,
    name:string,
    password:string,
    code:string,
    errorTexts:AuthErrors = AuthErrorText,
    registerPath:string = window.location.pathname,
    invitePhone:string = ``,
    state:number = 0,
    huahuaState:number = 0,
    type:string = `m`,
    userSource:string = GetSemSource (),
    inviteCode:number = null
):Promise<[Error, boolean]> => {
    return axios ( {
        url: ServerPService + `/register.htm`,
        method: `POST`,
        data: stringify ( {
            phone, name, password, code, registerPath, invitePhone, state, huahuaState, type, userSource, inviteCode
        } )
    } ).then ( res => {
        console.dir ( res ) ;
        if ( res.status === 200 ) {
            const code = res.data?.code ;
            switch ( code ) {
            case `1`:
                if ( inviteCode ) {
                    Cookies.set ( `inviteLoginParams`, JSON.stringify ( { invite_login_page: `大作M站注册页`, invite_code: getUrlParam ( `inviteCode` ) || `` } ), { domain: getDomain (), path: `/`, expires: 7 } ) ;
                }
                return [null, true] as [Error, boolean] ;
            case `5`:
            case `6`:
            case `7`:
            case `8`:
                throw new VCodeError ( errorTexts.registerErrorVCode ) ;
            case `10`:
            case `11`:
                throw new InviteError ( errorTexts.registerErrorInvite ) ;
            default:
                break ;
            }
            if ( res?.data === `1` ) {
                Cookies.remove ( `jhktracert` ) ;
                return [null, true] as [Error, boolean] ;
            } else {
                throw new UnknownError ( errorTexts.registerError ) ;
            }
        } else {
            throw new NetError ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        console.dir ( e ) ;
        return [e, false] ;
    } ) ;
} ;

/** 验证邀请码格式是否正确 */
export const AuthCheckInviteCode = ( inviteCode:string, errorTexts:AuthErrors = AuthErrorText ):boolean => {
    inviteCode = trim ( inviteCode ) ;
    if ( inviteCode === `` ) {
        throw new InviteError ( errorTexts.checkNoInvite ) ;
    }
    return true ;
} ;

/** 服务端验证邀请码是否正确 */
export const AuthCheckInviteCodeFromServer = async ( invitePhone:string, errorTexts:AuthErrors = AuthErrorText ):Promise<[Error, boolean]> => {
    return axios ( {
        url: ServerPService + `/send-code.htm`,
        method: `POST`,
        data: {
            invitePhone
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            const code = res.data.toString () ;
            switch ( code ) {
            case `0`:
                throw new InviteError ( errorTexts.checkNoInvite ) ;
            case `-1`:
                throw new InviteError ( errorTexts.checkExpireInvite ) ;
            case `-2`:
                throw new InviteError ( errorTexts.checkErrorInvite ) ;
            case `1`:
                return [null, true] as [Error, boolean] ;
            case `2`:
                return [null, true] as [Error, boolean] ;
            default:
                throw new UnknownError ( errorTexts.undefinedError ) ;
            }
        } else {
            throw new NetError ( JSON.stringify ( res ) ) ;
        }
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;

export enum AuthVCodeType {
    /** 注册 */
    register= 0,
    /** 忘记密码 */
    forget= 1
}
/**
 * 发送验证码
 * @param phone 手机号码
 * @param type 类型(0:注册；1:忘记密码)
 * @param imgCode 图形验证码
 * @param errorTexts 错误文本
 * @constructor
 */
export const AuthSendVCode = ( phone:string, type: AuthVCodeType, imgCode:string, errorTexts:AuthErrors = AuthErrorText ):Promise<[Error, boolean]> => {
    return axios ( {
        url: ServerPService + `/send-code.htm`,
        method: `get`,
        params: {
            account: phone,
            codeType: type,
            imgcode: imgCode
        }
    } ).then ( res => {
        if ( res.status === 200 ) {
            const data = res.data ;
            // 显示各个错误码
            const code = data?.code?.toString() ;
            const message = data?.message ;
            if ( data.errorCode ) {
                throw new NetError ( data.errorCode ) ;
            }
            if ( data.err_code === `isv.BUSINESS_LIMIT_CONTROL` ) {
                throw new NetError ( errorTexts.sendVCodeBusy ) ;
            }
            // 显示错误
            const showErr = () => {
                if ( data.err ) {
                    switch ( data.err ) {
                    case `-5`:
                        throw new NetError ( errorTexts.sendVCodeUserBusy1 ) ;
                    case `-3`:
                        throw new NetError ( errorTexts.sendVCodeUserBusy2 ) ;
                    case `-2`:
                        throw new NetError ( errorTexts.sendVCodeUserBusy3 ) ;
                    default:
                        throw new NetError ( errorTexts.undefinedError ) ;
                    }
                } else {
                    throw new UnknownError ( errorTexts.undefinedError ) ;
                }
            } ;
            switch ( code ) {
            case `-2`: return [new PhoneError ( ( type === 0 ? errorTexts.sendVCodeHasAccount : errorTexts.sendVCodeHasNoAccount ) || message ), true] as [Error, boolean] ;
            case `0`: throw new PhoneError ( errorTexts.sendVCodeErrorPhone || message ) ;
            case `-1`: throw new NetError ( errorTexts.sendVCodeBusy || message ) ;
            case `1`: return [null, true] as [Error, boolean] ; // 成功
            default: showErr () ;
            }
        } else {
            throw new NetError ( JSON.stringify ( res ) ) ;
        }
    } ).finally ( () => {
        // 不论成功与否，记录注册的电话号码
        AuthSendRegPhone ( phone ) ;
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;

/** 验证码地址 */
export const AuthImgCodeSrc = ServerMetaSearch + `/getCode.htm` ;

/** 刷新图形验证码 */
export const AuthRefreshImgCodeSrc = () => {
    const t = `?t=` + new Date ().getTime () ;
    return AuthImgCodeSrc + t ;
} ;

/** 记录用户注册信息:包括注册成功或注册失败 */
export const AuthSendRegPhone = ( phone:string ) => {
    axios ( {
        url: ServerPService + `/reg-record.htm`,
        method: `get`,
        params: {
            phone
        }
    } ).catch ( e => {
        throw e ;
    } ) ;
} ;

/** 重置密码
 * @param phone 电话号码
 * @param code 短信验证码
 * @param password 密码
 * @param errorTexts 错误文本
 * @constructor
 */
export const AuthSendResetPassword = ( phone:string, code:string, password:string, errorTexts:AuthErrors = AuthErrorText ):Promise<[Error, boolean]> => {
    return axios ( {
        method: `POST`,
        url: ServerPService + `/reset-password.htm`,
        data: stringify ( {
            account: trim ( phone ),
            isPhone: 1,
            code: trim ( code ),
            password: trim ( password )
        } )
    } ).then ( res => {
        if ( res.status === 200 ) {
            const r = res.data.toString () ;
            switch ( r ) {
            case `1`:
                return [null, true] as [Error, boolean] ;
            case `2`:
                throw new NetError ( errorTexts.checkExpireVCode ) ;
            case `3`:
                throw new NetError ( errorTexts.checkErrorVCode ) ;
            default:
                throw new NetError ( errorTexts.resetPasswordError ) ;
            }
        } else {
            throw new NetError ( errorTexts.netError ) ;
        }
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;

/** 向服务器登录
 * @param phone 电话号码
 * @param password 密码
 * @param errorTexts 错误文本
 * @param clientType 客户端种类 3:移动端
 * @return [错误，token]
 */
export const AuthSendLogin = async ( phone:string, password:string, errorTexts:AuthErrors = AuthErrorText, clientType = null ):Promise<[Error, string]> => {
    return axios ( {
        url: ServerPService + `/www-login.htm`,
        method: `POST`,
        data: stringify (
            {
                phone: trim ( phone ),
                password: trim ( password ),
                client_type: clientType,
                mobileIdentification: clientType === 3 ? getDeviceFingerprint () : ``
            }
        )
    } ).then ( res => {
        if ( res.status === 200 ) {
            const data = res.data ;
            if ( data.recode.length > 10 || data.recode === `1` ) {
                if ( getUrlParam ( `inviteCode` ) ) {
                    Cookies.set ( `inviteLoginParams`, JSON.stringify ( { invite_login_page: `大作M站注册页`, invite_code: getUrlParam ( `inviteCode` ) || `` } ), { domain: getDomain (), path: `/`, expires: 7 } ) ;
                }
                const token = data.recode ;
                return [null, token] as [Error, string] ;
            } else {
                throw new NetError ( errorTexts.loginError ) ;
            }
        } else {
            throw new NetError ( errorTexts.netError ) ;
        }
    } ).catch ( e => {
        return [e, null] ;
    } ) ;
} ;

/** 登录成功后调用,设置cookie,生成redirect
 * @param token
 * @param errorTexts
 * @return [Error, redirect]
 */
export const AuthLoginSuccess = ( token:string, redirectUrl:string='', errorTexts:AuthErrors = AuthErrorText ):[Error, string] => {
    try {
        if(!token) throw new Error("No Token")
        // 设置跳转；
        let redirect:string = decodeURIComponent ( redirectUrl ) ;
        const collect = getQueryString ( `pagefrom` ) ;
        if ( collect && collect === `bookmarklet` ) {
            const b64Obj = JSON.parse ( window.name ) ;
            if ( b64Obj[0].location ) {
                redirect = b64Obj[0].location ;
            } else {
                redirect = decodeURIComponent ( redirect ) ;
            }
        } else {
            redirect = decodeURIComponent ( redirect ) ;
        }
        redirect = AuthFilterRedirect ( redirect ) ;
        // 如果来自登出网页则不设置来源
        if ( redirect && redirect.indexOf ( `login-out` ) > -1 ) {
            redirect = `` ;
        }
        // 检测cookie
        if ( !navigator.cookieEnabled ) {
            throw new NetError ( errorTexts.checkCookieFailed ) ;
        }
        // 设置cookie
        Cookies.set ( `jhk-personal`, token, {
            domain: `.bigbigwork.com`,
            path: `/`,
            expires: 30
        } ) ;
        // https用
        Cookies.set ( `dashisousuo`, token, {
            domain: `.bigbigwork.com`,
            path: `/`,
            expires: 30,
            samesite: `none`,
            secure: true
        } ) ;
        // 设置登录token
        Cookies.set ( `userLoginFlag`, `true`, {
            domain: `bigbigwork.com`
        } ) ;
        return [null, redirect || ``] ;
    } catch ( e ) {
        return [e, null] ;
    }
} ;
/** 新注册用户登录跳转 */
export const AuthNewUserLogin = ( redirect:string, defaultRedirect:string = PageSearchIndex ) => {
    try {
        let _from = `` ;
        // 新注册用户，跳转到统计页面
        if ( window.top !== window.self ) {
            // 有父页面，取得父页面来源
            const url = getParentUrl () ;
            _from = url && encodeURIComponent ( url ) ;
            console.log ( _from ) ;
            const href = PageRegisterSuccess + `?&from=` + ( _from || `` ) + `&redirect=` + ( redirect ? encodeURIComponent ( redirect ) : PageSearchIndex ) ;
            try {
                window.top.postMessage ( {
                    type: `LoginSuccess`,
                    value: href
                }, `http://www.j-h-k.com/` ) ;
            } catch ( e ) {
                console.dir ( e ) ;
            }
            window.parent.location.href = redirect || defaultRedirect ;
        } else {
            _from = getQueryString ( `from` ) && getQueryString ( `from` ) ;
            const href = PageRegisterSuccess + `?&from=` + ( _from || `` ) + `&redirect=` + ( redirect ? encodeURIComponent ( redirect ) : PageSearchIndex ) ;
            console.log ( href ) ;
            window.location.href = href || defaultRedirect ;
        }
    } catch ( e ) {
        throw e ;
    }
} ;
/** 用户登录跳转 */
export const AuthLoginRedirect = ( redirect:string, defaultRedirect:string = PageSearchIndex ) => {
    const href = AuthFilterRedirect ( window.location.href ) ;
    // 不是新注册用户，登录成功后跳转
    // 请求父页面的登陆方法(iframe方法，旧版登陆)
    if ( window.top !== window.self ) {
        try {
            // window.top.postMessage({type:"LoginSuccess",value:redirect|| Config.Pages.Search},'http://www.j-h-k.com/');
            window.top.postMessage ( {
                type: `LoginSuccess`,
                value: redirect || defaultRedirect
            }, `http://www.j-h-k.com/` ) ;
        } catch ( e ) {
            console.dir ( e ) ;
        }
        try {
            // window.parent.location.href = redirect || Config.Pages.Search;
            window.parent.location.href = redirect || defaultRedirect ;
        } catch ( e ) {
            console.dir ( e ) ;
        }
    } else {
        // 采集器登陆
        if ( redirect === `broswerPlugin` ) {
            return window.close () ; // 登陆完就关闭视窗
        }
        window.location.href = redirect || href || defaultRedirect ;
    }
} ;

/** 筛选跳转页面, 这些页面不能redirect跳转，而是跳转到默认页面 */
export const AuthFilterRedirect = ( redirect:string ) => {
    if ( !redirect || redirect === `null` ) redirect = null ;
    try {
        // 对比window.location.href和需要筛选的页面的pathname
        const redirectPath = new URLParse ( redirect ).pathname ;
        const arr = [
            PageSearchIndex,
            PageLogin,
            PageLoginOut,
            PageRegister,
            PageRegisterSuccess,
            PageForget,
            PageNewRegister,
            PagePinliteLogin,
            PagePinliteRegister,
            PageMobileWeixinAuth,
            PageMobileLoginPhone,
            PageMobileForgetnew,
            PageMobileRegisterPhone
        ] ;
        for ( const i in arr ) {
            const filterPath = new URLParse ( arr[i] ).pathname ;
            if ( filterPath === redirectPath ) return null ;
        }
        return redirect ;
    } catch ( e ) {
        throw e ;
    }
} ;
/** 取得父页面来源 */
const getParentUrl = () => {
    let url = null ;
    if ( parent !== window ) {
        try {
            url = parent.location.href ;
        } catch ( e ) {
            url = document.referrer ;
        }
    }
    return url ;
} ;

/** 修改手机绑定 */
export const AuthSendChangeBindPhone = ( phone:string, vCode:string, errorTexts:AuthErrors = AuthErrorText ) => {
    return axios ( {
        method: `POST`,
        url: ServerPService + `/changeBindPhone.htm`,
        data: {
            phone: trim ( phone ),
            code: trim ( vCode )
        }
    } ).then ( res => {
        if ( res.status !== 200 ) { throw new NetError ( errorTexts.netError ) ; }
        const r = res.data ;
        const code = parseInt ( r.code ) ;
        const err = r.err ;
        switch ( err ) {
        // 一小时请求超过五次
        case `-5`: throw new NetError ( errorTexts.sendVCodeUserBusy1 ) ;
            // 一分钟之内请求两次
        case `-3`: throw new NetError ( errorTexts.sendVCodeUserBusy2 ) ;
            // 当天请求上限
        case `-2`: throw new NetError ( errorTexts.sendVCodeUserBusy3 ) ;
        }
        switch ( code ) {
        // 该手机号已经被注册
        case 9: throw new NetError ( errorTexts.sendVCodeHasAccount ) ;
            // 验证码超时
        case 8: throw new NetError ( errorTexts.checkExpireVCode ) ;
            // 验证码错误
        case 7: throw new NetError ( errorTexts.checkErrorVCode ) ;
            // 未获取验证码
        case 6: throw new NetError ( errorTexts.sendVCodeNoCode ) ;
            // 验证码为空
        case 5: throw new NetError ( errorTexts.checkNoVCode ) ;
            // 不是手机号码
        case 2: throw new NetError ( errorTexts.checkInvalidPhone ) ;
        // 成功
        case 1: return [null, true] ;
        // 用户未登录
        case 0: throw new NetError ( errorTexts.checkInvalidPhone ) ;
        }
        if ( r.code === 1 || r.code === `1` ) {
            return [null, true] ;
        } else {
            throw new UnknownError ( errorTexts.undefinedError ) ;
        }
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;
/** 绑定手机号 */
export const AuthBindPhone = async ( phone:string, password:string, vCode:string, token:string, errorTexts:AuthErrors = AuthErrorText ) => {
    if ( !token ) {
        return [new UnknownError ( errorTexts.unlogin ), false] ;
    }
    return axios ( {
        method: `POST`,
        url: ServerPService + `/bind-phone.htm`,
        withCredentials: true,
        data: stringify ( {
            phone: trim ( phone ),
            password: trim ( password ),
            code: trim ( vCode ),
            user_token: token
        } )
    } ).then ( res => {
        if ( res.status !== 200 ) { throw new NetError ( errorTexts.netError ) ; }
        const r = res.data ;
        const code = parseInt ( r.code ) ;
        const err = r.err ;
        switch ( err ) {
        // 一小时请求超过五次
        case `-5`: throw new NetError ( errorTexts.sendVCodeUserBusy1 ) ;
            // 一分钟之内请求两次
        case `-3`: throw new NetError ( errorTexts.sendVCodeUserBusy2 ) ;
            // 当天请求上限
        case `-2`: throw new NetError ( errorTexts.sendVCodeUserBusy3 ) ;
        }
        switch ( code ) {
        // 已被注册，执行合并流程
        case 9:
            throw new NetError ( errorTexts.sendVCodeHasAccount ) ;
            // 验证码超时
        case 8: throw new NetError ( errorTexts.checkExpireVCode ) ;
            // 验证码错误
        case 7: throw new NetError ( errorTexts.checkErrorVCode ) ;
            // 未获取验证码
        case 6: throw new NetError ( errorTexts.sendVCodeNoCode ) ;
            // 验证码为空
        case 5: throw new NetError ( errorTexts.checkNoVCode ) ;
            // 请输入6~16位有效字符
        case 4: throw new NetError ( errorTexts.checkInvalidPassword ) ;
            // 不是手机号码
        case 2: throw new NetError ( errorTexts.checkInvalidPhone ) ;
            // 成功
        case 1: return [null, true] ;
            // 未登录
        case 0: throw new UnknownError ( errorTexts.unlogin ) ;
        }
        if ( r.code === 1 || r.code === `1` ) {
            return [null, true] ;
        } else {
            throw new UnknownError ( errorTexts.undefinedError ) ;
        }
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;
/** 发送合并账号验证码 */
export const AuthSendMergeVCode = async ( phone:string, errorTexts:AuthErrors = AuthErrorText ):Promise<[Error, boolean]> => {
    const token = await getToken () ;
    if ( !token ) {
        return [new UnknownError ( `请登录后执行合并` ), false] ;
    }
    return axios ( {
        url: ServerMetaSearch + `/merge/send-code.htm`,
        method: `GET`,
        params: {
            account: trim ( phone ),
            codeType: 3,
            token: token
        }
    } ).then ( res => {
        if ( res.status !== 200 ) {
            throw new NetError ( errorTexts.netError ) ;
        }
        const data = res.data ;
        const status = data.status + `` ;
        const code = data.code + `` ;
        const err = data.err + `` ;
        // 成功
        if ( code === `1` ) {
            return [null, true] as [Error, boolean] ;
        }
        if ( status === `442` ) {
            throw new NetError ( `该手机号已经绑定了微信,<br/>请查证后重新绑定。` ) ;
        }
        if ( status === `443` ) {
            throw new NetError ( `距离该手机号码上次合并账户满一年后,<br/>才可以再次合并账号` ) ;
            // throw new NetError ( data.message || `距离该手机号码上次合并账户满一年后,<br/>才可以再次合并账号。` ) ;
        }
        if ( data.err_code === `isv.BUSINESS_LIMIT_CONTROL` ) {
            throw new NetError ( errorTexts.sendVCodeBusy ) ;
        }
        if ( code === `-2` ) {
            throw new PhoneError ( errorTexts.sendVCodeHasNoAccount || data.message ) ;
        }
        if ( code === `0` ) {
            throw new PhoneError ( errorTexts.sendVCodeErrorPhone || data.message ) ;
        }
        if ( code === `-1` ) {
            throw new NetError ( errorTexts.sendVCodeBusy || data.message ) ;
        }
        if ( err ) {
            switch ( err ) {
            case `-5`: throw new NetError ( errorTexts.sendVCodeUserBusy1 ) ;
            case `-3`: throw new NetError ( errorTexts.sendVCodeUserBusy2 ) ;
            case `-2`: throw new NetError ( errorTexts.sendVCodeUserBusy3 ) ;
            default: throw new VCodeError ( errorTexts.sendVCodeError ) ;
            }
        }
        throw new VCodeError ( errorTexts.sendVCodeError ) ;
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;

/** 合并账号 */
export const AuthMergePhone = async ( phone: string, password:string, vCode:string, token:string, errorTexts:AuthErrors = AuthErrorText ) => {
    return axios ( {
        url: ServerPService + `/merge/mergeAccount.htm`,
        method: `POST`,
        data: stringify ( {
            phone: trim ( phone ),
            password: trim ( password ),
            code: trim ( vCode ),
            accessToken: token
        } )
    } ).then ( ( res ) => {
        if ( res.status !== 200 ) {
            throw new NetError ( errorTexts.netError ) ;
        }
        const r = res.data ;
        const code = parseInt ( r.code ) ;
        const err = parseInt ( r.err ) ;
        if ( r.data === `合并成功` ) {
            return [null, true] ;
        }
        if ( r.data === `failure` ) {
            // 一小时请求超过五次
            throw new NetError ( `合并失败，请稍后再试` ) ;
        } else if ( err === -3 ) {
            // 一分钟之内请求两次
            throw new NetError ( `操作频繁,请一分钟后再试` ) ;
        } else if ( err === -2 ) {
            // 当天请求上限
            throw new NetError ( `操作频繁,请明天再试` ) ;
        } else if ( code === 9 ) {
            // 已被注册，执行合并流程
            return [new NetError ( `该手机号已经被注册` ), true] ;
        } else if ( code === 8 ) {
            throw new NetError ( `验证码超时` ) ;
        } else if ( code === 7 ) {
            throw new VCodeError ( `验证码错误` ) ;
        } else if ( code === 6 ) {
            throw new VCodeError ( `未获取验证码` ) ;
        } else if ( code === 5 ) {
            throw new VCodeError ( `验证码为空` ) ;
        } else if ( code === 4 ) {
            throw new PasswordError ( `请输入6~16位有效字符` ) ;
        } else if ( code === 2 ) {
            throw new PhoneError ( `不是手机号码` ) ;
        } else if ( code === 1 ) {
            // 成功
            return [null, true] ;
        } else {
            throw new NetError ( r.data || `未知错误` ) ;
        }
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;

/** 微信APPID */
export const AuthWeixinAppID = isDev () ? `wx0afd72a03c77c4f5` : `wx9ab5e35131e04844` ;

/** 获取微信授权页面地址 */
export const AuthWeixinAuth = ( redirect:string = PageMobileWeixinAuth ) => {
    const scope = `snsapi_userinfo` ;
    // const scope = `snsapi_base` ;
    const redirectUri = redirect ;
    const responseType = `code` ;
    const state = `` ;
    return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${AuthWeixinAppID}&redirect_uri=${encodeURIComponent ( redirectUri )}&response_type=${responseType}&scope=${scope}&state=${state}#wechat_redirect` ;
} ;

/** 拉取微信配置 */
export const AuthFetchWXConfig = () => {

} ;
/** 初始化微信配置 */
export const AuthCreateWXJS = () => {
    const wxconfig = new Map () ;
    return {
        // 跳转到新URL
        push: ( url:string ) => {

        }
    } ;
} ;
export type AuthWeixinUserInfo = {
    id:string,
    token:string,
    userType:number,
    phone:number
}
/** 使用微信code登录 */
export const AuthWeixinAutoLogin = ( code:string, errorTexts:AuthErrors = AuthErrorText, inviteCode:number = undefined ):Promise<[Error, AuthWeixinUserInfo]> => {
    return axios ( {
        url: ServerPService + `/wechatPublicAccount/getUser`,
        method: `GET`,
        params: {
            code,
            userSource: GetSemSource (),
            mobileIdentification: getDeviceFingerprint (),
            inviteCode
        }
    } ).then ( async ( res ) => {
        if ( res.status !== 200 ) { throw new NetError ( errorTexts.netError ) ; }
        const data = res.data ;
        if ( data.accessToken || data.token ) {
            const userInfo:AuthWeixinUserInfo = {
                id: data.id,
                token: data.accessToken || data.token,
                userType: data.user_type,
                phone: data.phone
            } ;
            if ( inviteCode ) {
                Cookies.set ( `inviteLoginParams`, JSON.stringify ( { invite_login_page: `大作M站注册页`, invite_code: getUrlParam ( `inviteCode` ) || `` } ), { domain: getDomain (), path: `/`, expires: 7 } ) ;
            }
            return [null, userInfo] as [Error, AuthWeixinUserInfo] ;
        } else {
            throw new Error ( `微信登录失败` ) ;
        }
    } ).catch ( e => {
        return [e, null] as [Error, AuthWeixinUserInfo] ;
    } ) ;
} ;
/** 不重定向地址 */
export const filterRedirectPath = [`/loginnew.htm`, `/login-out.htm`, `/registernew.htm`, `/registerSuccessAndBaiduRecord.htm`, `/forgetnew.htm`, `/newregisterpage.htm`, `/pinlite/login.html`, `/pinlite/register.html`, `/weixin-auth.htm`, `/login-phone.htm`, `/forgetnew.htm`, `/register-phone.htm`] ;

/** 新版微信扫码 手机号登录跳转 */
export const CommonLoginAction = ( options: {redirectUrl?: string, token: string }, islogin:boolean = false ) => {
    // 过滤redirectUrl  实际应用域名不同,只校验path
    const { token } = options ;
    if ( window.top === window.self ) {
        // @ts-ignore
        innerLogin ( token, options.redirectUrl, islogin ) ;
        return ;
    }
    iframeLogin ( token ) ;
} ;
/**
 * 其他域名二维码登录跳转(同时登录大作)
 * @param options
 */
export const thirdPartyLogin = async ( options: { redirectUrl: string, token: string }, autoRedirect:boolean = true ) => {
    let redirect ;
    try {
        redirect = options.redirectUrl || getQueryString ( `redirect` ) || `` ;
        Cookies.set ( `jhk-personal`, options.token, {
            domain: getDomain ( ),
            path: `/`,
            expires: 30
        } ) ;
        const isIncognito = await checkIsIncognito () ;
        if ( !isIncognito ) {
            await cookieSet ( `jhk-personal`, options.token, { path: `/`, expires: 30, domain: `.bigbigwork.com` } ) ;
            await cookieSet ( `dashisousuo`, options.token, { path: `/`, expires: 30, sameSite: `none`, secure: true, domain: `.bigbigwork.com` } ) ;
        }
        if ( autoRedirect ) {
            window.location.href = redirect || location.href ;
        }
    } catch ( e ) {
        throw e ;
    } finally {
        if ( autoRedirect ) {
            if ( redirect ) {
                window.location.href = redirect ;
            } else {
                window.location.reload();
            }
        }
    }
} ;

export const innerLogin = async ( token: string, redirectUrl: string, islogin: false ) => {
    function setToken ( token ) {
        Cookies.set ( `jhk-personal`, token, {
            domain: getDomain ( location.hostname ),
            path: `/`,
            expires: 30
        } ) ;
        // https用
        Cookies.set ( `dashisousuo`, token, {
            domain: getDomain ( location.hostname ),
            path: `/`,
            expires: 30,
            samesite: `none`,
            secure: true
        } ) ;
        const behance = { name: `behance`, icon: `${process.env.CDN_WIMG_PATH}/website/website-undefined/77ccb66c-374e-4c5c-a03c-887807eb24d1.jpg`, bgcolor: `` } ;
        const datu = { name: `datu`, icon: `${process.env.CDN_WIMG_PATH}/website/website-undefined/77ccb66c-374e-4c5c-a03c-887807eb24d1.jpg`, bgcolor: `` } ;
        if ( location.href.includes ( `bh` ) ) {
            Cookies.set ( `website`, JSON.stringify ( behance ), {
                domain: `.bigbigwork.com`
            } ) ;
        } else if ( location.href.includes ( `rabbit` ) ) {
            Cookies.set ( `website`, JSON.stringify ( datu ), {
                domain: `.bigbigwork.com`
            } ) ;
        }
    }
    let redirect = redirectUrl || getQueryString ( `redirect` ) || `` ;
    redirect = AuthFilterRedirect ( redirect ) ;
    /* bigbigwork下处理 */
    if ( location.href.includes ( `bigbigwork.com` ) ) {
        setToken ( token ) ;
        if(islogin) {
            BigBigWork.emit('similarLogin', token)
            return
        }
        window.location.href = redirectUrl || location.href ;
        // return
    }
    /* 非bigbigwork域名下处理 */
    setToken ( token ) ;
    if ( await checkIsIncognito () ) {
    /* 隐私模式 */
        console.log ( `隐私模式` ) ;
        const mainSiteAuth = PageSearchAuth ;
        // const mainSiteAuth = `http://www-local.bigbigwork.com:10103/auth`
        const jumpUrl = `${PageSearchAuth}?token=${encodeURIComponent(token)}&redirect=${encodeURIComponent ( redirectUrl || location.href )}` ;
        window.location.href = jumpUrl ;
    } else {
    /* 非隐私模式 */
        console.log ( `非隐私模式` ) ;
        /* 同步token到bigbigwork */
        await cookieSet ( `jhk-personal`, token, {
            domain: `.bigbigwork.com`,
            path: `/`,
            expires: 30,
            samesite: `none`,
            secure: true
        } ) ;
        await cookieSet ( `dashisousuo`, token, {
            domain: `.bigbigwork.com`,
            path: `/`,
            expires: 30,
            samesite: `none`,
            secure: true
        } ) ;
        window.location.href = redirectUrl || location.href ;
    }
} ;
export const AuthBindPhoneNew = async ( phone:string, password:string, vCode:string, token:string, errorTexts:AuthErrors = AuthErrorText ) => {
    if ( !token ) {
        return [new UnknownError ( errorTexts.unlogin ), false] ;
    }
    return axios ( {
        method: `POST`,
        url: ServerPService + `/bind-phone2.htm`,
        data: stringify ( {
            phone: trim ( phone ),
            password: trim ( password ),
            code: trim ( vCode ),
            user_token: token
        } )
    } ).then ( res => {
        if ( res.status !== 200 ) { throw new NetError ( errorTexts.netError ) ; }
        const r = res.data ;
        const code = parseInt ( r.code ) ;
        const err = r.err ;
        switch ( err ) {
        // 一小时请求超过五次
        case `-5`: throw new NetError ( errorTexts.sendVCodeUserBusy1 ) ;
            // 一分钟之内请求两次
        case `-3`: throw new NetError ( errorTexts.sendVCodeUserBusy2 ) ;
            // 当天请求上限
        case `-2`: throw new NetError ( errorTexts.sendVCodeUserBusy3 ) ;
        }
        switch ( code ) {
        // 已被注册，执行合并流程
        case 9:
            throw new NetError ( errorTexts.sendVCodeHasAccount ) ;
            // 验证码超时
        case 8: throw new NetError ( errorTexts.checkExpireVCode ) ;
            // 验证码错误
        case 7: throw new NetError ( errorTexts.checkErrorVCode ) ;
            // 未获取验证码
        case 6: throw new NetError ( errorTexts.sendVCodeNoCode ) ;
            // 验证码为空
        case 5: throw new NetError ( errorTexts.checkNoVCode ) ;
            // 请输入6~16位有效字符
        case 4: throw new NetError ( errorTexts.checkInvalidPassword ) ;
            // 不是手机号码
        case 2: throw new NetError ( errorTexts.checkInvalidPhone ) ;
            // 成功
        case 1: return [null, true] ;
            // 未登录
        case 0: throw new UnknownError ( errorTexts.unlogin ) ;
        }
        if ( r.code === 1 || r.code === `1` ) {
            return [null, true] ;
        } else {
            throw new UnknownError ( errorTexts.undefinedError ) ;
        }
    } ).catch ( e => {
        return [e, false] ;
    } ) ;
} ;
export const iframeLogin = ( token: string ) => {
} ;

export const GetSemSource = () => {
    const tracert = Cookies.get ( `jhktracert` ) ;
    const parsedTracert = tracert ? JSON.stringify ( JSON.parse ( tracert ) ) : null ;
    const source = getQueryString(`utm_source`);
    console.log('source', source)
    let dataSource = null
    const urlTracert = JSON.stringify ( {
        source: ``,
        medium: ``,
        campaign: ``,
        content: ``,
        term: ``,
        url: window.location.href
    } ) ;
    if(source == null || source == "") {
        dataSource = {
            source: ``,
            medium: ``,
            campaign: ``,
            content: ``,
            term: ``,
            url: window.location.href
        }
    } else {
        dataSource = {
            source: source,
            medium: getQueryString(`utm_medium`),
            term: getQueryString(`utm_term`),
            campaign: getQueryString(`utm_campaign`),
            url: window.location.href,
            content: ''
        };
        const content = getQueryString(`utm_content`);
        if (content != null && content != "") {
            dataSource['content'] = content;
        }
    }
    const userSource = JSON.stringify(dataSource);
    console.log('userSource', userSource)
    return encodeURIComponent ( parsedTracert || userSource ) ;
} ;

/* *************************** 登录注册V2接口 ***************************** */
export type AuthVCodeTypeV2 = `0` | `1` | `2` | `3` | `4` ;
/**
 * 发送验证码V2
 * @param phone 手机号码
 * @param codeType 类型(0:注册,1:忘记密码,2:修改密码,3:绑定功能,4:小程序名片预留手机号)
 * @param ticket 人机校验票据（https://cloud.tencent.com/document/product/1110/36841）
 * @param randstr 人机校验随机字符串
 * @param errorTexts 错误文本
 * @constructor
 */
export const AuthSendVCodeV2 = async ( phone:string, codeType: AuthVCodeTypeV2, ticket: string, randstr:string, errorTexts:AuthErrors = AuthErrorText ):Promise<[Error, boolean]> => {
    try {
        const token = await getToken () ;
        const url = window.localStorage.getItem ( `test-183602` ) ? `/sendCode/test183602` : `/sendCode` ;
        const res = await axios ( {
            method: `POST`,
            url: ServerPService + url,
            data: stringify ( {
                phone: trim ( phone ),
                codeType: trim ( codeType ),
                ticket: ticket,
                randstr: randstr,
                user_token: token
            } )
        } ) ;
        if ( res.data.status !== 200 ) {
            switch ( res.data.status ) {
            case 501: throw new PhoneError ( errorTexts.checkNoPhone ) ;
            case 502: throw new PhoneError ( errorTexts.checkInvalidPhone ) ;
            case 503: throw new UnknownError ( `codeType错误` ) ;
            case 504: throw new VCodeError ( errorTexts.sendVCodeHasAccount ) ;
            case 505: throw new VCodeError ( errorTexts.sendVCodeHasNoAccount ) ;
            case 509: throw new VCodeError ( errorTexts.checkErrorVCode ) ;
            case 510: throw new VCodeError ( errorTexts.sendVCodeBusy ) ;
            default: throw new UnknownError ( res.data.data || '未知错误' ) ;
            }
        } else {
            return [null, true] ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
} ;

export type FastSignInConfig = {
    phone: string,
    code: string,
    type: number, // 客户端类型：3:移动端
    inviteCode?: string,
    userSource?: string,
    url?: string
}

/**
 * 手机验证码注册登录
 * @param config
 * @param errorTexts
 * @constructor
 */
export const AuthSignInV2 = async ( config:FastSignInConfig, errorTexts:AuthErrors = AuthErrorText ):Promise<[Error, any]> => {
    try {
        const params = {
            phone: trim ( config.phone ),
            code: trim ( config.code ),
            type: config.type,
            inviteCode: trim ( config.inviteCode ),
            userSource: trim ( config.userSource ),
            url: trim ( config.url ),
            mobileIdentification: config.type === 3 ? getDeviceFingerprint () : ``
        } ;
        const res = await axios ( {
            url: ServerPService + `/signInCode`,
            method: `POST`,
            data: stringify ( params )
        } ) ;
        if ( res.data.status !== 200 ) {
            switch ( res.data.status ) {
            case 501: throw new PhoneError ( errorTexts.checkInvalidPhone ) ;
            case 502: throw new UnknownError ( errorTexts.checkErrorVCode ) ;
            case 503: throw new UnknownError ( errorTexts.checkErrorVCode ) ;
            case 504: throw new UnknownError ( errorTexts.checkExpireVCode ) ;
            case 505: throw new UnknownError ( `注册失败` ) ;
            case 506: throw new UnknownError ( `登录失败` ) ;
            case 507: throw new UnknownError ( `用户被禁` ) ;
            default: throw new UnknownError ( res.data.data ) ;
            }
        } else {
            return [null, res.data] ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
} ;

export const AuthSetPassword = async ( password:string, errorTexts:AuthErrors = AuthErrorText ):Promise<[Error, any]> => {
    try {
        const token = await getToken () ;
        if ( !token ) throw new UnknownError ( `请登录后设置密码` ) ;
        const res = await axios ( {
            url: ServerPService + `/user/set/pwd`,
            method: `POST`,
            data: stringify ( {
                token,
                pwd: password
            } )
        } ) ;
        if ( res.data.status !== 200 ) {
            switch ( res.data.status ) {
            default: throw new UnknownError ( res.data.data ) ;
            }
        } else {
            return [null, true] ;
        }
    } catch ( e ) {
        return [e, null] ;
    }
} ;
//
// export const InviteCodeStatus = async (ops: {code: string}) => {
//     try {
//         const res = await axios ( {
//             url: teamInviteDetail,
//             params: {
//                 code: ops.code
//             },
//             method: `POST`,
//         } ) ;
//         if ( res.status === 200 && res.data.code === 200) {
//             return res.data;
//         }
//         return Object.assign ( res.data, { status: 500 } ) ;
//     } catch ( e ) {
//         throw e ;
//     }
// }
