import 'core-js/stable' ;
import Vue from "vue" ;
import CreateApp from "./app" ;
import { Store } from "vuex" ;
import {
    CreateCrossDomain,
    CreateProxy
} from "bbw-common" ;
import Router from "vue-router" ;

window.Promise = Promise ;
window.routerHistory = [] ;

/** 初始化客户端前调用 */
const beforeInitClient = async function ( app, store:Store<any>, router ) {
    /** 初始化axios请求拦截 */
    try {
        if ( __DEV__ && !console.dir ) {
            console.dir = ( msg ) => {
                console.log ( JSON.stringify ( msg ) ) ;
            } ;
        }
        store.commit ( `setClientWidth`, document.body.clientWidth ) ;
        await CreateCrossDomain ().catch ( e => { throw e ; } ) ;
        window.addEventListener ( `resize`, () => {
            store.commit ( `setClientWidth`, document.body.clientWidth ) ;
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;

/**
 * 修改 vue-router 的push方法
 */
const originalPush = Router.prototype.push ;
// @ts-ignore
Router.prototype.push = function push ( location, onResolve, onReject ) {
    if ( onResolve || onReject ) {
        return originalPush.call ( this, location, onResolve, onReject ) ;
    }
    return originalPush.call ( this, location ).catch ( err => err ) ;
} ;

/** 创建客户端 */
const CreateEntryClient = async () => {
    try {
        const { app, router, store } = CreateApp () ;
        if ( window.__INITIAL_STATE__ ) {
            store.replaceState ( window.__INITIAL_STATE__ ) ;
        }
        router.onReady ( async () => {
            // Add router hook for handling asyncData.
            // Doing it after initial route is resolved so that we don't double-fetch
            // the data that we already have. Using router.beforeResolve() so that all
            // async components are resolved.
            router.beforeResolve ( ( to, from, next ) => {
                const matched = router.getMatchedComponents ( to ) ;
                const prevMatched = router.getMatchedComponents ( from ) ;
                let diffed = false ;
                const activated = matched.filter ( ( c, i ) => {
                    return diffed || ( diffed = ( prevMatched[i] !== c ) ) ;
                } ) ;
                const asyncDataHooks = activated.map ( c => c.asyncData ).filter ( _ => _ ) ;
                if ( !asyncDataHooks.length ) {
                    return next () ;
                }

                // bar.start()
                Promise.all ( asyncDataHooks.map ( hook => hook ( { store, route: to } ) ) )
                    .then ( () => {
                        // bar.finish()
                        next () ;
                    } )
                    .catch ( next ) ;
            } ) ;
            // 初始化客户端
            await beforeInitClient ( app, store, router ) ;
            // 挂载客户端DOM
            app.$mount ( `#app` ) ;
            await CreateProxy ( ) ;
            // 环境设为客户端
            store.commit ( `setIsClient` ) ;
        } ) ;
    } catch ( e ) {
        throw e ;
    }
} ;
// service worker
// if ('https:' === location.protocol && navigator.serviceWorker) {
//   navigator.serviceWorker.register('/service-worker.js')
// }

CreateEntryClient () ;

export default CreateEntryClient ;

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept ( ) ;
}
