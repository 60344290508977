import LRU from "lru-cache" ;
import { AxiosResponse } from "axios" ;
import { ResultItem } from "./resultItem" ;
import {SearchItem} from "@scripts/searchItem";
/** 对象存储 */
export const CacheItems:LRU<string, ResultItem | SearchItem> = new LRU ( {
    max: 2048,
    maxAge: 1000 * 60 * 60 * 5
} ) ;
/** 搜索结果缓存 */
export const CacheSearchResult:LRU<string, AxiosResponse> = new LRU ( {
    max: 200,
    maxAge: 1000 * 60 * 60 * 5
} ) ;

/** 其余元素缓存 */
export const Cache:LRU<string, any> = new LRU ( {
    max: 200,
    maxAge: 1000 * 60 * 60 * 5
} ) ;

// @ts-ignore
if ( module.hot ) {
    // @ts-ignore
    module.hot.accept () ;
}
