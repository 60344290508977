import {ResultItem} from "../scripts/resultItem";

/** 是否是开发模式 */
export const isDev = () => {
    // @ts-ignore
    return __DEV__ || process?.env?.NODE_ENV === `development`;
};
/** 是否是热更新模式 */
export const isHot = () => {
    return __HOT__ || process?.env?.WATCH === `WATCH`;
};

