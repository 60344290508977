/* eslint-disable camelcase */
import { getToken } from "@scripts/tools" ;
import {
    allBoards,
    boardAssociateWord,
    boardImgCopy,
    boardImgMove,
    cancelFollowBoard,
    cooperationMemberList,
    createSectionBoard,
    delCooperationMember,
    deleteImg,
    delImage,
    delSection,
    delChildBoardToRecycle,
    editImgInfo,
    editSection,
    exitCooperation,
    favToBoard,
    favToSection,
    followBoard,
    getAllMyBoardsMini,
    getImgDetail,
    imgToSection,
    NewCreateBoards,
    relatedBoard,
    saveRemark,
    sectionBoardImgCopy,
    sectionBoardImgMove,
    sectionDetail,
    sectionDetailList,
    sectionList,
    switchCooperation,
    switchCooperationV2,
    tranformAdmin,
    getAllMyBoardsMiniV2,
    favToSectionMeta,
    getImgDetailBoard,
    inviteMemberList,
    inviteMember,
    boardAdmainUrl,
    getImgDetail2
} from '@constants/servlet' ;
import axios, { AxiosResponse } from 'axios' ;
import qs from 'qs' ;
import {BoardMataToSearch} from "@scripts/BoardManager";
// import { ShowMessage } from "bbw-components" ;

const errInfo = { status: 500 } ;
const formDataHeader = {
    'Content-Type': `application/x-www-form-urlencoded; charset=UTF-8`
} ;
/**
 * @description 删除图片
 * @param imgid
 * @param is_share
 */
export const deleteSingleImage = async ( imgid: number, is_share ) => {
    return axios ( {
        url: delImage,
        params: {
            user_token: await getToken (),
            imgIds: imgid,
            is_share: is_share ? 1 : 0
        }
    } ) ;
} ;
/**
 * @description 编辑图片信息
 * @param imgId
 * @param lableIds
 * @param info
 * @param boardId
 */
export const editImageInfo = async ( imgId, lableIds, info, boardId ) => {
    const res = await axios ( {
        url: editImgInfo,
        method: `POST`,
        headers: formDataHeader,
        data: qs.stringify ( {
            user_token: await getToken (),
            imgId,
            lableIds,
            info,
            boardId
        } )
    } ) ;
    if ( res.status === 200 ) {
        return res.data.data ;
    } else {
        return Promise.reject ( new Error ( `checkApi-editImageInfo` ) ) ;
    }
} ;
/**
 * @description 保存标签
 * @param content
 * @param lookId
 * @param callback
 */
export const toSaveRemark = async ( content, lookId ) => {
    const res = await axios ( {
        method: `POST`,
        url: saveRemark,
        headers: formDataHeader,
        data: qs.stringify ( {
            content: content,
            lookId: lookId
        } )
    } ) ;
    if ( res.status === 200 ) {
        return res.data.data ;
    } else {
        return Promise.reject ( new Error ( `checkApi-toSaveRemark` ) ) ;
    }
} ;

export const fetchImgInfo = async ( options: { img_id: string }, cancelToken? ) => {
    const user_token = await getToken () ;
    const params = Object.assign ( { user_token }, options ) ;
    return axios ( {
        url: getImgDetail2,
        params,
        cancelToken: cancelToken
    } ) ;
} ;

export const toDeleteImg = async ( options: { imgIds: string, is_share: number } ) => {
    const token = await getToken () ;
    return axios ( {
        url: deleteImg,
        params: {
            user_token: token,
            imgIds: options.imgIds,
            is_share: options.is_share
        }
    } ) ;
} ;
/**
 * @description 获取我的所有图片集接口 (旧的 以后可删)
 * @param user_token 本人token
 * @param uid 他人id
 * @param is_public -1 查找所有的图片集 0 查找私有的图片集 1 查找公开的图片集
 */
export const fetchAllMyBoards = async ( options: { uid: any, is_public: number } ) => {
    try {
        const res = await axios ( {
            url: getAllMyBoardsMini,
            params: {
                user_token: await getToken (),
                uid: options.uid,
                is_public: options.is_public
            }
        } ) ;
        if ( res.status === 200 ) {
            return res.data.list ;
        }
    } catch ( e ) {
        return [] ;
    }
    // $.ajax({
    //     url: _self.switchCollaborate? Config.Servlet.getAllMyBoardsMini : Config.Servlet.getAllMyBoards,
    //     data: {
    //         user_token: user_token,
    //         uid: uid,
    //         is_public: is_public
    //     }
    // }).done(function (data) {
    //     _self.boardList = data
    //     callback(data.list)
    // })
} ;
/**
 * @description 切换图片集(协作/一般)
 * @param board_id 协作图片集的id
 * @return { Promise<T> }
 */
export const collaborateSwitch = async ( board_id ) => {
    const errInfo = { status: 500 } ;
    try {
        const data = new FormData () ;
        data.append ( `user_token`, await getToken () ) ;
        data.append ( `board_id`, board_id ) ;
        const res = await axios ( {
            url: switchCooperation,
            method: `POST`,
            headers: {
                'Content-Type': `application/json;charset=UTF-8`
            },
            data
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        } else {
            return errInfo ;
        }
    } catch ( e ) {
        return errInfo ;
    }
} ;
/**
 * @description 获取board关联词   生产
 * @param boardId
 */
export const getBoardAssociateWord = async ( boardId ) => {
    try {
        const res = await axios ( {
            url: `${boardAssociateWord}${boardId}`
            // url: `https://board.bigbigwork.com/boardAssociateWord/${boardId}`
        } ) ;
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data ;
        } else {
            return errInfo ;
        }
    } catch ( e ) {
        return errInfo ;
    }
} ;
/**
 * @description 修改图集协作状态及管理状态
 * @param options
 */
export const toSwitchCooperation = async ( options: { bid?: string, uuid?: string, is_share: number, admin_auth_flag: number } ):Promise<AxiosResponse> => {
    const errInfo = {
        status: 500
    } ;
    try {
        const res = await axios ( {
            url: switchCooperationV2,
            params: {
                board_id: options.bid || ``,
                uuid: options.uuid || ``,
                is_share: options.is_share,
                admin_auth_flag: options.admin_auth_flag,
                user_token: await getToken ()
            }
        } ) ;
        if ( res.status === 200 ) {
            return res ;
        } else {
            return Object.assign ( res, errInfo ) ;
        }
    } catch ( e ) {
        throw errInfo ;
        // return errInfo ;
    }
} ;
/**
 * @description 获取操作图片集的权限
 * @param options
 */
export const getBoardAdmain = async ( options: { bid?: number, uuid?: number} ) => {
    const errCode = {
        code: 500
    } ;
    try {
        const res = await axios ( {
            url: boardAdmainUrl,
            params: {
                user_token: await getToken (),
                board_id: options.bid,
                uuid: options.uuid
            }
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return errCode ;
    } catch ( e ) {
        return errCode ;
    }
} ;
/**
 * @description 关注图片集
 * @param ops
 */
export const toFollowBoard = async ( ops:{bid: string, uuid: string} ) => {
    const errCode = {
        code: 500,
        meg: `当前网络异常,请稍后重试`
    } ;
    try {
        const data = new FormData () ;
        data.append ( `board_id`, ops.bid || `` ) ;
        data.append ( `uuid`, ops.uuid || `` ) ;
        data.append ( `user_token`, await getToken () ) ;
        const res = await axios ( {
            method: `POST`,
            url: followBoard,
            data
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return errCode ;
    } catch ( e ) {
        return errCode ;
    }
} ;
export const toCancelFollowBoard = async ( ops:{bid: string, uuid: string} ) => {
    const errCode = {
        code: 500,
        meg: `当前网络异常,请稍后重试`
    } ;
    try {
        const data = new FormData () ;
        data.append ( `board_id`, ops.bid || `` ) ;
        data.append ( `uuid`, ops.uuid || `` ) ;
        data.append ( `user_token`, await getToken () ) ;
        const res = await axios ( {
            method: `POST`,
            url: cancelFollowBoard,
            data
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return errCode ;
    } catch ( e ) {
        return errCode ;
    }
} ;
/**
 * @description 协作成员列表
 * @param options
 */
export const getCooperationMemberList = async ( options: { bid?: number, uuid?: string, page: number, rows: number } ) => {
    const errCode = {
        code: 500
    } ;
    try {
        const res = await axios ( {
            url: cooperationMemberList,
            params: {
                user_token: await getToken (),
                board_id: options.bid,
                page: options.page,
                rows: options.rows,
                uuid: options.uuid
            }
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return errCode ;
    } catch ( e ) {
        return errCode ;
    }
} ;
/**
 * @description 获取团队图片集邀请成员弹窗中 成员列表
 * @param options
 */
export const getInviteMemberList = async ( options: { bid?: number, id?: number} ) => {
    const errCode = {
        code: 500
    } ;
    try {
        const res = await axios ( {
            url: inviteMemberList,
            params: {
                token: await getToken (),
                board_id: options.bid,
                enterprise_id: options.id
            }
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return errCode ;
    } catch ( e ) {
        return errCode ;
    }
} ;
/**
 * @description 邀请协作成员
 * @param options
 */
export const getInviteMember = async ( options: { bid?: number, uid?: number} ) => {
    const errCode = {
        code: 500
    } ;
    try {
        const res = await axios ( {
            url: inviteMember,
            params: {
                user_token: await getToken (),
                board_id: options.bid,
                user_id: options.uid
            }
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return errCode ;
    } catch ( e ) {
        return errCode ;
    }
} ;

/**
 * @description 移除协作成员
 * @param options
 */
export const toDelCooperationMember = async ( options: { bid?, uuid?, uid } ) => {
    const errInfo = {
        status: 200
    } ;
    try {
        const data = new FormData () ;
        data.append ( `board_id`, options.bid || `` ) ;
        data.append ( `uuid`, options.uuid || `` ) ;
        data.append ( `user_id`, options.uid ) ;
        data.append ( `user_token`, await getToken () ) ;
        const res = await axios ( {
            method: `POST`,
            url: delCooperationMember,
            data
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return errInfo ;
    } catch ( e ) {
        return errInfo ;
    }
} ;
/**
 * @description 移交权限
 * @param options
 */
export const toTransformAdmin = async ( options: { bid?, uuid?, uid } ) => {
    const errInfo = {
        status: 200
    } ;
    try {
        const data = new FormData () ;
        data.append ( `board_id`, options.bid || `` ) ;
        data.append ( `uuid`, options.uuid || `` ) ;
        data.append ( `user_id`, options.uid ) ;
        data.append ( `user_token`, await getToken () ) ;
        const res = await axios ( {
            method: `POST`,
            url: tranformAdmin,
            data
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return errInfo ;
    } catch ( e ) {
        return errInfo ;
    }
} ;
/**
 * @description 退出协作
 * @param ops
 */
export const toExitCooperation = async ( ops:{bid?, uuid?: string} ) => {
    try {
        const data = new FormData () ;
        data.append ( `user_token`, await getToken () ) ;
        data.append ( `board_id`, ops.bid || `` ) ;
        data.append ( `uuid`, ops.uuid || `` ) ;
        const res = await axios ( {
            url: exitCooperation,
            method: `POST`,
            data
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return { status: 500 } ;
    } catch ( e ) {
        return { status: 500 } ;
    }
} ;
/**
 * @description 获取子图集详情
 * @param id
 */
export const getSectionDetail = async ( uuid ) => {
    try {
        const res = await axios ( {
            url: sectionDetail,
            params: {
                user_token: await getToken (),
                child_board_uuid: uuid
            }
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return {
            code: 500
        } ;
    } catch ( e ) {
        return {
            code: 500
        } ;
    }
} ;
/**
 * @description 获取子图集列表
 */
export const getSectionList = async ( options: { uuid: string, page: number, min_id: number } ) => {
    try {
        const res = await axios ( {
            url: sectionDetailList,
            params: {
                user_token: await getToken (),
                page: options.page,
                min_id: options.min_id,
                child_board_uuid: options.uuid
            }
        } ) ;
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data ;
        }
        return { code: 500 } ;
    } catch ( e ) {
        return { code: 500 } ;
    }
} ;
/**
 * @description 获取相似board
 * @param ops
 */
export const getRelatedBoard = async ( ops: {bid: string, uuid: string} ) => {
    try {
        const res = await axios ( {
            url: relatedBoard,
            params: {
                board_id: ops.bid,
                uuid: ops.uuid
            }
        } ) ;
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data.data ;
        }
        return [] ;
    } catch ( e ) {
        return [] ;
    }
} ;
/**
 * @description 收藏弹框 获取所有图片集
 */
export const getAllBoard = async () => {
    try {
        const res = await axios ( {
            url: allBoards,
            params: {
                user_token: await getToken ()
            }
        } ) ;
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data.data ;
        }
        return [] ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 大图页 也收藏在以下图片集
 */
export const getDetailBoard = async ( ops: {id:string, cancelToken? } ) => {
    try {
        const res = await axios ( {
            // url: getImgDetailBoard,
            url: 'https://board.bigbigwork.com/boardNew/imgInBoards',
            cancelToken: ops.cancelToken,
            params: {
                id: ops.id,
                token: await getToken ()
            }
        } ) ;
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data.data ;
        }
        return [] ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 创建子图片集
 */
export const createSection = async ( ops: { boardId?: number, uuid?: string, title: string } ) => {
    try {
        const res = await axios ( {
            method: `POST`,
            url: createSectionBoard,
            headers: formDataHeader,
            data: qs.stringify ( {
                user_token: await getToken (),
                board_id: ops.boardId,
                title: ops.title,
                uuid: ops.uuid || ``
            } )
        } ) ;
        if ( res.status && res.data.code === 200 ) {
            console.log ( `chuang建`, res ) ;
            return res.data ;
        }
        return res.data ;
    } catch ( e ) {
        return null ;
    }
} ;
/**
 * @description 收藏弹框 获取子图片集列表
 */
export const getAllSection = async ( ops: { boardId: number, page?:number, uuid?: number } ) => {
    try {
        const res = await axios ( {
            url: sectionList,
            params: {
                user_token: await getToken (),
                board_id: ops.boardId,
                page: ops.page,
                rows: 10,
                uuid: ops.uuid
            }
        } ) ;
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data.data ;
        }
        return [] ;
    } catch ( e ) {
        console.error ( e ) ;
        return [] ;
    }
} ;

/**
 * @default 创建图片集
 */
export const createBoard = async ( ops: { title: string, categyIds?: number, isPublic: number, info?: string } ) => {
    try {
        const res = await axios ( {
            url: NewCreateBoards,
            headers: formDataHeader,
            method: `POST`,
            data: qs.stringify ( {
                user_token: await getToken (),
                title: ops.title,
                categyIds: -1,
                isPublic: ops.isPublic,
                info: ``
            } )
        } ) ;
        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data ;
        }
        return null ;
    } catch ( e ) {
        throw e ;
    }
} ;

interface FavItem {
    board_id: number
    img_id: number
    lableIds: string
    label: string
    img_info: string
    is_continue: boolean
}

/**
 * @description 收藏到图片集
 */
export const favImgToBoard = async ( ops: FavItem ) => {
    try {
        const res = await axios ( {
            url: favToBoard,
            method: `POST`,
            headers: formDataHeader,
            data: qs.stringify ( {
                board_id: ops.board_id,
                img_id: ops.img_id,
                user_token: await getToken (),
                lableIds: ops.lableIds || ``,
                label: ops.label || ``,
                img_info: ops.img_info || ``,
                is_continue: Number ( ops.is_continue ) || 0
            } )
        } ) ;

        if ( res.status === 200 && res.data.status === 200 ) {
            return res.data.data ;
        }
        return res.data ;
    } catch ( e ) {
        throw e ;
    }
} ;

/** 大作图片信息 */
type favImgToSectionOps = {
    img_id: number,
    label: string,
    img_name: string,
    child_board_uuid: string,
    is_continue?: 1 | 0
}
type favImgToSectionMetaOps = {
    img_name: string,
    img_info: string,
    other_url: string,
    col_groupImages: string,
    img_source: string,
    img_width: number,
    img_height: number,
    owner_name: string,
    label: string,
    download_url: string,
    child_board_uuid: string,
    is_continue?: 1 | 0
}
type favCall = ( ( ops: favImgToSectionOps, isMeta : false )=>Promise<any> ) | ( ( ops: favImgToSectionMetaOps, isMeta : true )=>Promise<any> )
/**
 *  @description 收藏到子图片集
 */
export const favImgToSection = async ( ops:favImgToSectionOps & favImgToSectionMetaOps, isMeta:boolean ) => {
    try {
        const url = isMeta ? favToSectionMeta : favToSection ;
        let param ;
        if ( isMeta ) {
            param = {
                user_token: await getToken (),
                img_name: ops.img_name,
                img_info: ops.img_info,
                other_url: ops.other_url,
                col_groupImages: ops.col_groupImages,
                img_source: ops.img_source,
                img_width: ops.img_width,
                img_height: ops.img_height,
                owner_name: ops.owner_name,
                label: ops.label,
                download_url: ops.download_url,
                child_board_uuid: ops.child_board_uuid,
                is_continue: Number ( ops.is_continue ) || 0
            } ;
        } else {
            param = {
                user_token: await getToken (),
                img_id: ops.img_id,
                label: ops.label,
                img_name: ops.img_name,
                child_board_uuid: ops.child_board_uuid,
                is_continue: Number ( ops.is_continue ) || 0
            } ;
        }
        const res = await axios ( {
            url: url,
            method: `POST`,
            headers: formDataHeader,
            data: qs.stringify ( param )
        } ) ;
        if ( res.status === 200 && res.data ) {
            return res.data ;
        }
        return false ;
    } catch ( e ) {
        throw e ;
    }
} ;

/**
 * @description 编辑子图片集
 * @param ops
 */
export const toEditSection = async ( ops ) => {
    try {
        const res = await axios ( {
            url: editSection,
            method: `POST`,
            headers: formDataHeader,
            data: qs.stringify ( {
                user_token: await getToken (),
                child_board_uuid: ops.uuid,
                title: ops.title
            } )
        } ) ;
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data ;
        }
        return false ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 删除子图片集
 * @param ops
 */
export const toDelSection = async ( ops ) => {
    try {
        const res = await axios ( {
            url: delSection,
            method: `POST`,
            headers: formDataHeader,
            data: qs.stringify ( {
                user_token: await getToken (),
                child_board_uuid: ops.uuid
            } )
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return false ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 删除子图片集到回收站
 * @param ops
 */
export const childBoardToRecycle = async ( ops ) => {
    try {
        const res = await axios ( {
            url: delChildBoardToRecycle,
            method: `POST`,
            headers: formDataHeader,
            data: qs.stringify ( {
                user_token: await getToken (),
                child_board_uuid: ops.uuid
            } )
        } ) ;
        if ( res.status === 200 ) {
            return res.data ;
        }
        return false ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 上传图片到子图片集
 * @param ops
 */
export const uploadImgToSection = async ( ops ) => {
    try {
        const res = await axios ( {
            url: imgToSection,
            method: `POST`,
            headers: formDataHeader,
            data: qs.stringify ( {
                user_token: await getToken (),
                child_board_uuid: ops.uuid,
                img_name: ops.info || ``,
                label: ops.label || ``,
                signature_key: ops.signature_key || ``,
                url: ops.url || ``,
                jhResults: ops.jhResults
            } )
        } ) ;
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data ;
        }
        return false ;
    } catch ( e ) {
        throw e ;
    }
} ;
/**
 * @description 复制图片集图片
 * @param ops
 * user_token    string    用户token
 * s_bid    long    源图片集ID
 * t_bid    long    目标图片集ID
 * t_cbid_uuid    String    目标子图片集UUID
 * img_ids    long    要复制的图片IDs （例：20116501181,20116501180,20116501179）
 */
export const copyBoardImg = async ( ops ) => {
    const res = await axios ( {
        url: boardImgCopy,
        params: {
            user_token: await getToken (),
            s_bid: ops.s_bid,
            s_uuid: ops.s_uuid,
            t_bid: ops.t_bid,
            t_uuid: ops.t_uuid,
            t_cbid_uuid: ops.t_cbid_uuid,
            img_ids: ops.img_ids
        }
    } ) ;
    if ( res.status === 200 ) {
        return res.data ;
    }
    throw res ;
} ;
/**
 *  @description 子图片集复制
 * @param ops
 * user_token    string    用户token
 * s_bid    long    源图片集ID
 * s_cbid_uuid    String    源子图片集UUID
 * t_bid    long    目标图片集ID
 * t_cbid_uuid    String    目标子图片集UUID
 * img_ids    long    要复制的图片IDs （例：20116501181,20116501180,20116501179）
 */
export const copySectionBoardImg = async ( ops ) => {
    const res = await axios ( {
        url: sectionBoardImgCopy,
        params: Object.assign ( { user_token: await getToken () }, ops )
    } ) ;
    if ( res.status === 200 ) {
        return res.data ;
    }
    throw res ;
} ;
/**
 * @description 移动图片集图片
 * @param ops
 * user_token    string    用户token
 * s_bid    long    源图片集ID
 * t_bid    long    目标图片集ID
 * t_cbid_uuid    String    目标子图片集UUID
 * img_ids    long    要复制的图片IDs （例：20116501181,20116501180,20116501179）
 */
export const moveBoardImg = async ( ops ) => {
    const res = await axios ( {
        url: boardImgMove,
        params: {
            user_token: await getToken (),
            s_bid: ops.s_bid,
            s_uuid: ops.s_uuid,
            t_bid: ops.t_bid,
            t_uuid: ops.t_uuid,
            t_cbid_uuid: ops.t_cbid_uuid,
            img_ids: ops.img_ids
        }
    } ) ;
    if ( res.status === 200 ) {
        return res.data ;
    }
    throw res ;
} ;
/**
 * @description 移动子图片集图片
 * @param ops
 */
export const moveSectionBoardImg = async ( ops ) => {
    const res = await axios ( {
        url: sectionBoardImgMove,
        params: Object.assign ( { user_token: await getToken () }, ops )
    } ) ;
    if ( res.status === 200 ) {
        return res.data ;
    }
    throw res ;
} ;
/**
 * @description 设计云_获取图片集列表
 * @param ops
 */
export const getBoardList = async ( ops:{ is_public: number, page: number, rows: number, uid?: string, uuid?: string } ) => {
    console.log ( ops ) ;
    try {
        const res = await axios ( {
            url: getAllMyBoardsMiniV2,
            params: {
                user_token: await getToken (),
                is_public: ops.is_public,
                page: ops.page,
                rows: ops.rows,
                uid: ops.uid || ``,
                uuid: ops.uuid || ``
            }
        } ) ;
        if ( res.status === 200 && res.data.code === 200 ) {
            return res.data ;
        }
    } catch ( e ) {
        throw e ;
    }
} ;
