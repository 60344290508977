import LRUCache from "lru-cache";
import { Module, Store } from "vuex" ;

export type ContextState = {
    url: string,
    token: string,
    t: string,
    d: string,
    k: string,
    cookies: [],
    cache: { [key:string]: LRUCache<string, any>},
    serverLogin: boolean
}
export const Context: Module< ContextState, any> = {
    namespaced: true,
    state: {
        /** request的url */
        url: ``,
        /** request的token */
        token: ``,
        /** request的cookie */
        cookies: [],
        /** tdk */
        t: `大作AI绘画生成器_分享AI绘画,交流AI训练经验,学习AI绘画关键词生成`,
        d: `AI大作免费ai作画生成器,ai绘画在线生成器根据您的创意描述关键词，自动生成各种个性化ai绘画作品。在AI大作分享你的AI绘画创作，交流AI训练经验，学习AI绘画关键词生成。`,
        k: `ai作画生成器,ai绘画生成器,ai绘画网站,ai自动生成绘画软件,AI绘画关键词`,
        cache: {},
        serverLogin: false
    },
    mutations: {
        setContext ( state, payload = {} ) {
            const keys = Object.keys ( payload );
            for ( const k of keys ) {
                state[k] = payload[k]
            }
        },
        setUrl ( state, payload:string ) {
            state.url = payload ;
        },
        setToken: function ( state, val ) {
            state.token = val ;
        },
        setCache: function ( state, data ) {
            const { key, value } = data ;
            state.cache[key] = value ;
        },
        clearCache: function ( state ) {
            state.cache = {} ;
        },
        setTDK: function ( state, payload: {t:string, d:string, k:string }) {
            state.t = payload.t ;
            state.d = payload.d ;
            state.k = payload.k ;
            if ( typeof document !== `undefined` ) {
                document.title = payload.t ;
            }
        }
    }
} ;

export const CreatedContext = ( store: Store<unknown> ) => {
    if ( store.hasModule ( `ContextData` ) ) return ;
    store.registerModule ( `Context`, Context ) ;
} ;