import {
    ServerWww,
    ServerMetaSearch,
    ServerPayServer,
    ServerPinLite,
    ServerMobileServer,
    ServerRabbit, ServerPinliteMobile,
    ServerFanli,
    ServerWiki, ServerYasuotu,
    ServerKuotu, ServerAiPainting
} from "./servers" ;

export const PageLogin = ServerMetaSearch + `/loginnew.htm` ;
export const PageSearch = ServerMetaSearch + `/searchnew.htm` ;
export const PageRegister = ServerMetaSearch + `/registernew.htm` ;
export const PageForget = ServerMetaSearch + `/forgetnew.htm` ;
export const PageMyBigbigwork = ServerWww + `/myworks.htm` ;
export const PageCenter = ServerMetaSearch + `/centernew.htm` ;
export const PageVersionDiff = ServerMetaSearch + `/versiondifnew.htm` ;
export const PageGoToPay = ServerMetaSearch + `/gotopay.htm`;
export const PageVersionTeamDiff = ServerMetaSearch + `/team/vip` ;
export const PageVersionTeamReDiff = ServerMetaSearch + `/team/renew` ;
export const PageGroupbuy = ServerMetaSearch + `/groupbuy.html` ;
export const PageEveryDayHot = ServerMetaSearch + `/searchnew.htm?tag=Recommend` ;
export const PageLoginOut = ServerMetaSearch + `/login-out.htm` ;
export const PageLoginOutThird = ServerMetaSearch + `/p/login-out.html` ;
export const PageLoginOutFront = `https://xcx-img.bigbigwork.com/p/login-out.html` ;
export const PageAbout = ServerMetaSearch + `/aboutnew.htm` ;
export const PageAliPayUrl = `http://` + ServerPayServer + `/pay/alipay/link.htm` ;
export const PageExplore = ServerWww + `/explore/index.html` ;
export const PageCollectionTool = ServerWww + `/caijiqi/index.html` ;
export const PageExploreLogin = ServerWww + `/explore/index.html` ;
export const PageRegisterSuccess = ServerMetaSearch + `/registerSuccessAndBaiduRecord.htm` ;
export const PageSearchIndex = ServerWww + `/home` ;
export const PageSearchBoards = ServerWww + `/board/` ;
export const PageSearchEngine = ServerWww + `/tupian/` ;
export const PageSearchEngineNew = ServerWww + `/u/` ;
export const PageShareImage = ServerWww + `/sharebigimage.htm` ;
export const PageToPinterest = ServerMetaSearch + `/goto-pinterest.htm` ;
export const PageToBehance = ServerMetaSearch + `/goto-behance.htm` ;
export const PageToArtstation = ServerMetaSearch + `/goto-artstation.htm` ;
export const PageSearchEngineToLogin = ServerWww + `/tologin.htm` ;
export const PageHuahua = ServerMetaSearch + `/entrance-huahua.htm` ;
export const PageNetworkSearch = ServerWww + `/networkSearch.html` ;
export const PageExpandImage = ServerWww + `/expandImage.html` ;
export const PageKuotu = ServerKuotu + `/expandImage.html` ;
export const PageExcellentCourse = ServerWww + `/excellentCourse.htm` ;
export const PageCrossDomain = `//xcx-img.bigbigwork.com/p/crossdomain.html` ;
export const PageCrossDomainOut = `//xcx-img.bigbigwork.com/p/loginout.html` ;
export const PageSearchHome = ServerMetaSearch + `/searchhome.html` ;
export const PageNotFound = ServerWww + `/404.html` ;
export const PageRabbitHome = ServerRabbit + `/home` ;
export const PageRabbitSearch = ServerRabbit + `/tupian` ;
export const PageRabbitImage = ServerRabbit + `/image`;
export const PageUserVisit = ServerWww + `/userVisit.htm` ;
export const PageWiki = `http://wiki.bigbigwork.com/` ;
export const PagePinliteUnlogin = ServerPinLite + `/index` ;
export const PagePinliteTupian = ServerPinLite + `/tupian` ;
export const PagePinliteVip = ServerMetaSearch + `/pinlite/vip.html` ;
export const PagePinliteLogin = ServerMetaSearch + `/pinlite/login.html` ;
export const PagePinliteRegister = ServerMetaSearch + `/pinlite/register.html` ;
export const PageAliPayPinliteUrl = `http:` + ServerPayServer + `/pay/alipay/pinlite/link.htm` ;
export const PageClient = ServerWww + `/client.htm` ;
export const PagePinliteLoginAuth = ServerMetaSearch + `/pinlite/loginAuth.html` ;
export const PageSearchUnlogin = ServerWww + `/` ;
export const PageGoDesign = `https://www.go.design` ;
export const PagePinliteAuth = ServerPinLite + `/auth` ;
export const PageNewRegister = ServerMetaSearch + `/newregisterpage.htm` ;
export const PageWhatCollaborateboard = ServerWww + '/what-is-collaborateboard' ;
export const PageWhatPrivateBoard = ServerWiki + `/archives/what-is-secretboard` ;
export const PageLeave = ServerMetaSearch + 'leave.htm';
export const PageHome = ServerWww + `/home` ;
export const PageMobileLoginPhone = ServerMobileServer + `/account/login.html` ;
export const PageMobileLogout = ServerMobileServer + `/account/logout` ;
export const PageMobileBindPhone = ServerMobileServer + `/account/set_phone.html` ;
export const PageMobileBindPhoneSuccess = ServerMobileServer + `/account/set_phone/success.html` ;
export const PageMobileForgetnew = ServerMobileServer + `/account/reset_password.html` ;
export const PageMobileMergePhone = ServerMobileServer + `/account/merge_account.html` ;
export const PageMobileMergePhoneSuccess = ServerMobileServer + `/account/merge_account/success.html` ;
export const PageMobileMergePhoneFailed = ServerMobileServer + `/account/merge_account/fail.html` ;
export const PageMobileRegisterPhone = ServerMobileServer + `/account/register.html` ;
export const PageMobileWeixinAuth = ServerMobileServer + `/account/weixin_callback.html` ;
export const PageMobileHome = ServerMobileServer + `/tupian` ;
export const PageMobileUnlogin = ServerMobileServer ;
export const PageMobileSearch = ServerMobileServer + `/search` ;
export const PageMobileMycloud = ServerMobileServer + `/mycloud` ;
export const PageMobilePay = ServerMobileServer + `/pay/vip.html`;
export const PageMobilePaySuccess = ServerMobileServer + '/pay/vip/success.html';
export const PageMobilePinSource = ServerMobileServer + '/pin/source.html';
export const PageSearchAuth = ServerWww + '/auth.html';
export const PageMobileSecretboard = ServerMobileServer + `/help/secretboard/`;
export const PagePinliteMobileAuth = ServerPinliteMobile + '/auth';
export const PagePinliteMobileHome = ServerPinliteMobile + '/tupian';
export const PagePinliteMobileIndex = ServerPinliteMobile + '/index';
export const PagePinliteMobilePinSource = ServerPinliteMobile + '/pin/source.html';
export const PageMobilePayCheck = ServerMobileServer + '/pay/check.html'
export const PageFanliUnlogin = ServerFanli + '/index'
export const PageFanliHome = ServerFanli + '/home'
export const PageInvite = ServerWww + '/huodong/invite.htm'
export const PageMobileRegisterPhoneB = ServerMobileServer + `/account/b/register.html` ;
export const PageMobileloginOneKey = ServerMobileServer + `/account/b/login_onekey.html` ;
export const PageMobileThirdLogin = ServerMobileServer + `/auth/thirdlogin` ;
export const PageMobileLoginPhoneB = ServerMobileServer + `/account/b/login.html` ;
export const PageMobileLoginFastB = ServerMobileServer + `/account/b/login_fast.html` ;
export const PageMobileSetPasswordB = ServerMobileServer + `/account/b/set_password.html` ;
export const PageMobileForgetB = ServerMobileServer + `/account/b/reset_password.html` ;
export const PageMobileFanli = ServerMobileServer + '/fanli';
export const PageYasuotu = ServerYasuotu ;
export const PageAiCreate = ServerAiPainting + `/create`;
export const PageAiAuth = ServerAiPainting + `/auth`;