import { Module } from "vuex" ;
import axios from 'axios' ;
import { getPinUserCacheName, getDomain } from "@scripts/tools" ;
import Cookies from "js-cookie" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import { pinliteDomainList, ServerPinliteService, ServerPService } from "../../constants/servers" ;
import { getTokenCrossDomain } from "@scripts/CrossDomain" ;
import { loadData, LocalStorageRead, LocalStorageWrite, saveData } from "@scripts/LocalStorage" ;
import { ProxyType, SearchProxy } from "@scripts/Proxy";
import dayjs from "dayjs";

export interface pinUserState {
    /** 普通用户 */
    commonUser:string,
    /** 推荐用户 */
    recommendUser:string,
    /** loading */
    loading: boolean,
    /** token 验证签名 */
    sign: string,
    /** token 验证签名过期时间 */
    signExpires: number,
    /** 是否在获取签名 */
    signLoading:boolean,
    /** 上次验证的token */
    lastToken: string,
    /** pinlite搜索节点 */
    pinNodes: string[],
    /** pinlite搜索节点索引 */
    pinNodeIndex: number,
    /** pinlite 可用的域名 */
    pinliteDomain: string,
    /** 是否pinlite的域名都不可用 */
    pinliteDomainError: boolean,
    /** pinlite各个域名的状态 */
    pinliteDomainStatus: Array<{ domain:string, status:number }>,
    /** 图片服务器类型 */
    proxyType: ProxyType,
    /** 图片错误数量 */
    proxyErrorNum: number
}

export const PinUser:Module<pinUserState, any> = {
    namespaced: true,
    state: {
        commonUser: ``,
        recommendUser: ``,
        loading: false,
        sign: ``,
        lastToken: ``,
        signLoading: false,
        signExpires: -1,
        pinNodes: [
            `//pinlite.bigbigwork.com`,
            `//pinlite2.bigbigwork.com`
        ],
        pinNodeIndex: 0,
        pinliteDomain: process.env.PINLITE_DOMAIN,
        pinliteDomainError: false,
        pinliteDomainStatus: [],
        proxyType: `primary`,
        proxyErrorNum: 0
    },
    mutations: {
        setName: ( state, data: { type: number, name: string} ) => {
            const { type, name } = data ;
            switch ( type ) {
            case 1 : state.commonUser = name ; break ;
            case 2 : state.recommendUser = name ; break ;
            default: state.commonUser = name ;
            }
        },
        setSign: ( state, data ) => {
            state.sign = data ;
        },
        setSignExpires: ( state, data ) => {
            state.signExpires = data ;
        },
        setSignLoading: ( state, data ) => {
            state.signLoading = data ;
        },
        setLastToken: ( state, data ) => {
            state.lastToken = data ;
        },
        /** 切换接口节点 */
        switchPinNode: ( state, index?:number ) => {
            // @ts-ignore
            // eslint-disable-next-line no-undef
            _hmt.push ( [`_trackEvent`, `pinlite2`, `调用`] ) ;
            if ( typeof index === `number` ) {
                // 如果有明确的index, 则切换到对应节点
                if ( index < 0 || index >= state.pinNodes.length ) {
                    throw new Error ( `pinNode超出范围` ) ;
                }
                state.pinNodeIndex = index ;
            } else {
                // 没有指定index, 切换到下个节点
                const newIndex = ( state.pinNodeIndex + 1 ) % state.pinNodes.length ;
                state.pinNodeIndex = newIndex ;
            }
        },
        setPinliteDomain: ( state, domain:string ) => {
            state.pinliteDomain = domain ;
        },
        setPinliteDomainError: ( state, error ) => {
            state.pinliteDomainError = error ;
        },
        setPinliteDomainStatus: ( state, payload ) => {
            state.pinliteDomainStatus = payload ;
        },
        setProxyType: ( state, payload:ProxyType ) => {
            const tomorrow = dayjs ().startOf ( `day` ).add ( 1, `day` ) ;
            const expires = tomorrow.diff ( dayjs () ) ;
            if ( payload !== 'primary') {
                SearchProxy.currentListIndex = 1 ;
            }
            saveData (`ProxyType`, `default`, payload, { expires, unit: `millisecond` } ) ;
            state.proxyType = payload ;
        },
        getProxyType: ( state ) => {
            const proxyType = loadData ( `ProxyType`, `default` ) || `primary` ;
            state.proxyType = proxyType ;
        },
        addProxyErrorNum: ( state, payload:number = 1 ) => {
            state.proxyErrorNum += payload ;
        }
    },
    actions: {
        /** 获取token验证令牌
         * @param context
         * @param data (1)
         * @constructor
         */
        FETCH_SIGN: async ( context, data:number = 1 ):Promise<[Error, string]> => {
            try {
                if ( context.state.signLoading ) {
                    return new Promise ( ( resolve, reject ) => {
                        BigBigWork.once ( `ON_PIN_SIGN_SUCCESS`, ( sign:string ) => {
                            return resolve ( [null as Error, sign as string] ) ;
                        } ) ;
                        BigBigWork.once ( `ON_PIN_SIGN_FAILED`, ( e ) => {
                            return resolve ( [e, null as string] ) ;
                        } ) ;
                    } ) ;
                }
                context.commit ( `setSignLoading`, true ) ;
                const token = await getTokenCrossDomain () ;
                // 没有token的，失败
                if ( !token ) {
                    throw new Error ( `获取令牌失败，Token 不存在` ) ;
                }
                // 从cookie获取签名
                const cachedSign = Cookies.get ( `bbw-sign` )?.split( `||` ) ;
                if ( cachedSign && cachedSign[1] === token ) {
                    context.commit ( `setSign`, cachedSign[0] ) ;
                    context.commit ( `setSignLoading`, false ) ;
                    BigBigWork.emit ( `ON_PIN_SIGN_SUCCESS`, cachedSign[0] ) ;
                    return Promise.resolve ( [null, cachedSign[0]] ) ;
                }
                // cookie获取不到的情况下，发起验证请求
                const res = await axios ( {
                    method: `get`,
                    url: ServerPService + `/user/check`,
                    params: {
                        token: token,
                        type: data,
                        _t: new Date ().getTime ()
                    }
                } ) ;
                // 签名不成功的情况下，返回登录错误
                if ( res.status !== 200 ) {
                    return [new Error ( `LOGIN_ERROR` ), null] ;
                }
                context.commit ( `setLastToken`, token ) ;
                context.commit ( `setSign`, res.data ) ;
                Cookies.set ( `bbw-sign`, `${res.data}||${token}`, { domain: getDomain (), path: `/`, expires: 1 / 24 / 60 * 2 } ) ;
                BigBigWork.emit ( `ON_PIN_SIGN_SUCCESS`, res.data ) ;
                context.commit ( `setSignLoading`, false ) ;
                return [null as Error, res.data as string] ;
            } catch ( e ) {
                BigBigWork.emit ( `ON_PIN_SIGN_FAILED`, e ) ;
                context.commit ( `setSignLoading`, false ) ;
                return [e, null as string] ;
            }
        },
        /** 获取一个pinterest用户名 */
        FETCH_PIN_NAME: ( context, data:{ type: number, refresh:boolean } ) => {
            const { type = 1, refresh = false } = data ?? {} ;
            return new Promise ( ( resolve, reject ) => {
                // 获取用户名
                const fetchPinName = async ( params, cacheName ) => {
                    try {
                        const res = await axios ( {
                            url: context.getters.pinNode + `/pinterest/user`,
                            params: { ...params, _t: new Date ().getTime () }
                        } ) ;
                        if ( res.status === 200 && res.data.status === 200 ) {
                            LocalStorageWrite ( cacheName, ``, res.data.name, 1 ) ;
                            context.commit ( `setName`, { type: type, name: res.data.name } ) ;
                            BigBigWork.emit ( `PinUser/FETCH_PIN_NAME_${type}`, { type: type, name: res.data.name } ) ;
                            return [null, { type: type, name: res.data.name }] ;
                        }
                    } catch ( e ) {
                        return [e, null] ;
                    }
                } ;
                // 递归重试
                const fetch = async ( params, cacheName, retryTimes ) => {
                    const [err, res] = await fetchPinName ( params, cacheName ) ;
                    if ( err ) {
                        if ( retryTimes <= 2 ) {
                            // 调不通就尝试换节点
                            context.commit ( `switchPinNode` ) ;
                            return await fetch ( params, cacheName, retryTimes + 1 ) ;
                        } else {
                            return [new Error ( `重试次数过多` ), null] ;
                        }
                    } else {
                        return [null, res] ;
                    }
                } ;
                if ( !refresh && context.getters.getUser ( type ) ) {
                    // 该type已有用户名的，返回用户名'
                    const userName = context.getters.getUser ( type );
                    resolve ( userName ) ;
                } else if ( context.state.loading ) {
                    BigBigWork.once ( `PinUser/FETCH_PIN_NAME_${type}`, () => {
                        const userName = context.getters.getUser ( type );
                        resolve ( userName ) ;
                    } ) ;
                } else {
                    // 先从localStorage里面查找用户名
                    const cacheName = getPinUserCacheName ( type ) ;
                    const pinUserName = LocalStorageRead ( cacheName, `` ) ;
                    if ( pinUserName ) {
                        context.commit ( `setName`, { type: type, name: pinUserName } ) ;
                        return resolve ( pinUserName ) ;
                    }
                    // 没有就发出请求查找
                    context.state.loading = true ;
                    const params = type ? { type } : {} ;
                    fetch ( params, cacheName, 0 ).then ( result => {
                        const [err, res] = result ;
                        context.state.loading = false ;
                        if ( !err ) {
                            resolve ( res ) ;
                        } else {
                            BigBigWork.emit ( `PinUser/FETCH_PIN_NAME_${type}_FAIL`, err ) ;
                            reject ( err ) ;
                        }
                    } ) ;

                    // axios ( {
                    //     url: ServerPinliteService + `/pinterest/user`,
                    //     params
                    // } ).then ( ( res ) => {
                    //     if ( res.status === 200 && res.data.status === 200 ) {
                    //         localStorage.setItem ( cacheName, res.data.name ) ;
                    //         context.commit ( `setName`, { type: type, name: res.data.name } ) ;
                    //         BigBigWork.emit ( `PinUser/FETCH_PIN_NAME_${type}`, { type: type, name: res.data.name } ) ;
                    //         resolve ( { type: type, name: res.data.name } ) ;
                    //     } else {
                    //         BigBigWork.emit ( `PinUser/FETCH_PIN_NAME_${type}_FAIL`, res ) ;
                    //         reject ( new Error ( JSON.stringify ( res ) ) ) ;
                    //     }
                    // } ).finally ( () => {
                    //     context.state.loading = false ;
                    // } ).catch ( e => {
                    //     BigBigWork.emit ( `PinUser/FETCH_PIN_NAME_${type}_FAIL`, e ) ;
                    //     reject ( e ) ;
                    //     throw e ;
                    // } ) ;
                }
            } ) ;
        },
        /** 测试哪个pinlite域名可用 */
        async checkPinliteDomain ( context, payload: { needCache:boolean, type:String } = { needCache: true, type: '' } ) {
            const success = ( domain:string ) => {
                context.state.pinliteDomain = domain ;
                context.commit ( `setPinliteDomain`, domain ) ;
                return [null, domain] ;
            } ;
            /** 抓取服务器心跳包 */
            const fetchHeart = async ( domain ) => {
                try {
                    // 判断是否是手机端
                    if ( payload.type !== 'm' ) {
                        // www
                        const pre = __DEV__ ? `www-test` : `www` ;
                        const url = `https://${pre}.${domain}/api/heart` ;
                        const res = await axios.get ( url, { timeout: 1000 } ) ;
                        if ( res.status === 200 ) {
                            return [null, res] ;
                        } else {
                            return [new Error ( `心跳包无法抵达` ), null] ;
                        }
                    } else {
                        // mobile
                        const pre = __DEV__ ? `m-test` : `m` ;
                        const url = `https://${pre}.${domain}/api/heart` ;
                        const res = await axios.get ( url, { timeout: 1000 } ) ;
                        if ( res.status === 200 ) {
                            return [null, res] ;
                        } else {
                            return [new Error ( `心跳包无法抵达` ), null] ;
                        }
                    }
                } catch ( e ) {
                    return [e, null] ;
                }
            } ;
            // 统计pinlite连通性
            const stat = ( pinliteDomainStatus ) => {
                const linkable = pinliteDomainStatus
                    .filter ( val => val.status === 200 )
                    .map ( val => val.domain.match ( /\.[\d\w]+$/ ) ) ;
                const notLinkable = pinliteDomainStatus
                    .filter ( val => val.status !== 200 )
                    .map ( val => val.domain.match ( /\.[\d\w]+$/ ) ) ;
                    _hmt.push ( [`_trackEvent`, `PL跳转${payload.type}`, `不通（${notLinkable.join ( `/` )}）`, `通（${linkable.join ( `/` )}）`] ) ;
            } ;

            try {
                // 先获取缓存里的值
                if ( payload.needCache ) {
                    const cache = loadData ( `pinlite-default-domain`, `default` ) ;
                    if ( cache ) {
                        return success ( cache ) ;
                    }
                }
                // 没有缓存就准备从服务器获取
                const list = [...pinliteDomainList] ;
                // process.env.PINLITE_DOMAIN 的域名排第一
                list.sort ( function ( a, b ) {
                    if ( a.match ( process.env.PINLITE_DOMAIN ) ) {
                        return -1 ;
                    } else if ( b.match ( process.env.PINLITE_DOMAIN ) ) {
                        return 1 ;
                    } else {
                        return 0 ;
                    }
                } ) ;
                const pinliteDomainStatus = list.map ( ( val ) => {
                    return { domain: val, status: 500 } ;
                } ) ;
                let pinliteDomain ;
                // 检测每个域名的联通性 */
                for ( let i = 0 ; i < pinliteDomainStatus.length ; i++ ) {
                    const [err, res] = await fetchHeart ( pinliteDomainStatus[i].domain ) ;
                    if ( res ) {
                        pinliteDomainStatus[i].status = 200 ;
                        if ( !pinliteDomain ) pinliteDomain = pinliteDomainStatus[i].domain ;
                    }
                }
                if ( payload.needCache && pinliteDomain ) {
                    saveData ( `pinlite-default-domain`, `default`, pinliteDomain, { expires: 5, unit: `minute` } ) ;
                }
                success ( pinliteDomain || process.env.PINLITE_DOMAIN ) ;
                stat ( pinliteDomainStatus ) ;
                context.commit ( `setPinliteDomainError`, true ) ;
                context.commit ( 'setPinliteDomainStatus', pinliteDomainStatus ) ;
                if ( !pinliteDomain ) throw new Error ( `pinlite均无法联通` ) ;
                return [null, pinliteDomain] ;
            } catch ( e ) {
                return [e, process.env.PINLITE_DOMAIN] ;
            }
        }
    },
    getters: {
        /** 按照用户类型获取用户名 */
        getUser: ( context ) => ( type:number ) => {
            switch ( type ) {
            case 2 : return context.recommendUser ;
            case 1 : return context.commonUser ;
            default: return context.commonUser ;
            }
        },
        /** 当前搜索节点 */
        pinNode: ( context ) => {
            return context.pinNodes[context.pinNodeIndex] ;
        }
    }
} ;
