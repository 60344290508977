/* eslint-disable  camelcase */
import { StoreOptions, Module, Store } from 'vuex' ;
import { getTokenCrossDomain } from "@scripts/CrossDomain" ;
import { ServerAnalysis, ServerBigImage, ServerPService, ServerWww, ServerTeam } from "@constants/servers" ;
import Cookies from "js-cookie" ;
import axios from "axios" ;
import qs from "querystring" ;
import { getDomain, getToken, decodeBase64 } from "@scripts/tools" ;
import { BigBigWork } from "@scripts/BigBigWork" ;
import { recordLogOut } from "@scripts/RecordLogOut" ;
import { isDev } from "@scripts/utils" ;
import {teamsList} from "@constants/servlet";

export type StatsLoginOutParams = {
    cookie_token: string,
    memory_token: string,
    data: string,
    status: string,
    referer: string
}


export interface UserInfo {
    /** 是否在加载中 */
    loading: boolean,
    /** 是否拉取过用户数据 */
    finished: boolean,
    /** 用户id */
    id: number,
    /** 用户uuid */
    uuid: string,
    /** 用户id字符串 */
    idStr: string,
    /** 昵称 */
    name: string,
    /** 头像 */
    avatar: string,
    /** 手机号码 */
    phone: number,
    /** 是否是新用户 */
    isNewUser: number,
    /** 登录次数 */
    loginTimes: number,
    /** 登录天数 */
    loginDays:number,
    /** 是否已完成新手引导 */
    guideReadFlag: number,
    /** 用户类型 */
    userType: number,
    /** 剩余vip天数 **/
    vipDays: number,
    /** 个人vip冻结 */
    personalVipDays:number,
    /** 剩余pinterest天数 */
    pinterestDays: number,
    /** pinlite天数 */
    pinliteDays:number,
    /** 用户是否付过费 */
    isUserPay:number,
    /** 创建时间 **/
    createTime: number,
    /** 注册时间 (毫秒) */
    registerDays: number,
    /** 现在时间 */
    now: number,
    /** 服务器与本地的时间差 */
    diffTime: number,
    /** 微信信息 **/
    wx:{
        miniOpenid: string,
        wechatOpenid: string,
        /** 是否订阅过公众号 */
        subscribe: number,
        mpFlag: number,
        /** 是否加过企业微信 */
        exclusive_service_id: number,
        /** 是否加过企业微信 */
        flag_ai_kefu: number
    },
    maxTeamInfo: object,
    /** vip团队最多的团队详情 */
    // maxTeamInfo:{
    //     avatar_url: string,
    //     creator_id: number,
    //     id: number,
    //     max_member_num: number,
    //     member_num: number,
    //     name: string,
    //     pay_flag: number,
    //     uuid: string,
    //     vip_day: number,
    //     vip_expire_time: string
    // },
    /** 检查用户唯一性间隔 */
    checkTimeMs:number,
    /** 检查用户唯一性cookie名 */
    cookieName: string,
    /** 是否显示用户唯一性提示框 */
    isShowError: boolean,
    isRequesting: boolean,
    todoFn: Array<any>,
    /** 是否是受邀用户 */
    isInvitedUser: number,
    /** 受邀状态是否过期 */
    isInviteExpiry: number,
    /** 团队列表 */
    teamsList: Array<any>,
    /** 是否已经获取道团队列 */
    isGetedTeamList: boolean,
    /** 是否正在请求团队列表 */
    isReqTeamList: boolean,
    teamTodoFn: Array<any>,
    /** 用户类型(用户类型 默认0-大作用用户，2-大作机器账号) */
    type: number
}
// @ts-ignore
export const UserInfo: Module< UserInfo, any> = {
    namespaced: true,
    state: {
        loading: false,
        finished: false,
        id: -1,
        uuid: ``,
        idStr: ``,
        name: ``,
        avatar: ``,
        phone: 0,
        isNewUser: 0,
        loginTimes: 0,
        loginDays: 0,
        guideReadFlag: 0,
        userType: 0,
        vipDays: 0,
        /** 个人vip冻结 */
        personalVipDays: 0,
        pinterestDays: 0,
        pinliteDays: 0,
        isUserPay: 0,
        createTime: 0,
        registerDays: 0,
        now: 0,
        diffTime: 0,
        wx: {
            miniOpenid: ``,
            wechatOpenid: ``,
            subscribe: 0,
            mpFlag: 0,
            exclusive_service_id: null,
            flag_ai_kefu: 0
        },
        maxTeamInfo: {},
        checkTimeMs: isDev () ? 1000 * 60 * 5 : 1000 * 60 * 5,
        cookieName: `_uniq_ts`,
        isShowError: false,
        isRequesting: false,
        todoFn: [],
        isInvitedUser: 0,
        isInviteExpiry: 0,
        teamsList: [],
        /** 是否已经获取道团队列 */
        isGetedTeamList: false,
        /** 是否正在请求团队列表 */
        isReqTeamList: false,
        teamTodoFn: [],
        type:0
    },
    mutations: {
        setUserInfo: ( state:UserInfo, val:any ) => {
            state.id = val.userInfo.uid ;
            state.uuid = val.userInfo.uuid ;
            state.idStr = val.userInfo.idStr ;
            state.name = val.userInfo.name ;
            state.phone = val.userInfo.phone ;
            state.isNewUser = val.userInfo.is_new_user ;
            state.loginTimes = val.userInfo.logintimes ;
            state.loginDays = val.userInfo.logindays ;
            state.guideReadFlag = val.userInfo.guide_read_flag ;
            state.userType = val.userInfo.user_type ;
            state.avatar = val.userInfo.avatar ;
            state.vipDays = val.userInfo.vip_days ;
            state.personalVipDays = val.userInfo.personal_vip_days ;
            state.pinterestDays = val.userInfo.pinterest_days ?? 0 ;
            state.pinliteDays = val.userInfo.pinliteDays ?? 0 ;
            state.isUserPay = val.userInfo.is_user_pay ;
            state.isInvitedUser = val.invite?.is_invited_user || 0 ;
            state.isInviteExpiry = val.invite?.is_expiry || 0 ;
            state.createTime = val.userInfo.createTIme ;
            const registerDays = Math.floor(( val.userInfo.now - val.userInfo.createTIme ) / (24*60*60*1000))
            state.registerDays = val.userInfo.registerDays || registerDays ;
            state.now = val.userInfo.now ;
            state.diffTime = new Date ().getTime () - val.userInfo.now ;
            state.wx.miniOpenid = val.wx.mini_openid ;
            state.wx.mpFlag = val.wx.mpFlag ;
            state.wx.subscribe = val.wx.subscribe ;
            state.wx.wechatOpenid = val.wx.wechat_openid ;
            state.wx.exclusive_service_id = val.wx.exclusive_service_id ;
            state.wx.flag_ai_kefu = val.wx.flag_ai_kefu ;
            state.maxTeamInfo = { ...val.enterprise };
            state.type = val.userInfo.type || 0;
        },
        setUserTeam: ( state:UserInfo, val:any ) => {
            state.teamsList = [...val];
            /** 是否已经获取道团队列 */
            // isGetedTeamList: boolean,
                /** 是否正在请求团队列表 */
                // isReqTeamList: boolean,
        },
        updateReqTeamStatus: ( state:UserInfo, payload: { path, data }) => {
            if (!payload.path) return;
            state[payload.path] = payload.data || '';
        },
        /** 重置用户信息 **/
        resetUserInfo: ( state:UserInfo, rootState ) => {
            state.id = -1 ;
            state.uuid = `` ;
            state.idStr = `` ;
            state.name = `` ;
            state.phone = 0 ;
            state.isNewUser = 0 ;
            state.loginTimes = 0 ;
            state.guideReadFlag = 0 ;
            state.userType = -1 ;
            state.type = 0 ;
            state.avatar = `` ;
            state.vipDays = 0 ;
            state.personalVipDays = 0;
            state.pinterestDays = 0 ;
            state.pinliteDays = 0 ;
            state.isUserPay = 0 ;
            state.createTime = 0 ;
            state.registerDays = 0 ;
            state.loginDays = 0 ;
            state.wx.miniOpenid = `` ;
            state.wx.mpFlag = 0 ;
            state.wx.subscribe = 0 ;
            state.wx.wechatOpenid = `` ;
            state.maxTeamInfo = {};
            state.wx.flag_ai_kefu = 0 ;
            Cookies.remove ( `jhk-personal`, { domain: getDomain (), path: `/` } ) ;
            Cookies.remove ( `dashisousuo`, { domain: getDomain (), path: `/` } ) ;
        },
        setLoading: ( state: UserInfo, val:boolean ) => {
            state.loading = val ;
        },
        setFinish: ( state: UserInfo, val:boolean ) => {
            state.finished = val ;
        },
        updateIsRequesting: ( state: UserInfo, payload: boolean ) => {
            state.isRequesting = payload ;
        },
        updateTodoFn: ( state: UserInfo, payload: {add?: boolean, todoFn?: object} ) => {
            if ( payload.add ) {
                state.todoFn = [...state.todoFn, payload.todoFn] ;
            } else {
                state.todoFn = [] ;
            }
        },
        updateTeamTodoFn: ( state: UserInfo, payload: {add?: boolean, teamTodoFn?: object} ) => {
            if ( payload.add ) {
                state.teamTodoFn = [...state.teamTodoFn, payload.teamTodoFn] ;
            } else {
                state.teamTodoFn = [] ;
            }
        },
        setUserPhone: ( state: UserInfo, val:any ) => {
            state.phone = val ;
        }
    },
    actions: {
        /** **/
        STATS_LOGIN_OUT: async ( context, data: Partial<StatsLoginOutParams> ) => {
            const token = await getTokenCrossDomain () ;
            const defaultParams:StatsLoginOutParams = {
                cookie_token: token || ``,
                memory_token: ``,
                data: ``,
                status: ``,
                referer: window.location.href
            } ;
            const formData = { ...defaultParams, ...data } ;
            return axios ( {
                url: ServerBigImage + `/error/loginKick/stats`,
                method: `post`,
                headers: {
                    "Content-Type": `application/json`
                },
                data: JSON.stringify ( formData )
            } ) ;
        },
        /** 从服务端获取用户信息 */
        FETCH_USER_INFO: async ( context, isForce:boolean = false ) => {
            console.log(555555555555555555555555)
            const clearToDoFn = () => {
                if ( context.state.todoFn.length ) {
                    context.state.todoFn.forEach ( fn => fn ( false ) ) ;
                    context.commit ( `updateIsRequesting`, false ) ;
                    context.commit ( `updateTodoFn`, {} ) ;
                }
            } ;
            const onFailed = async ( res:any = {} ) => {
                console.log ( `检查失败FETCH_USER_INFO` ) ;
                await clearToDoFn () ;
                const token = encodeURIComponent(await getTokenCrossDomain ()) ;
                await context.dispatch ( `STATS_LOGIN_OUT`, {
                    cookie_token: token,
                    memory_token: context.rootState.token,
                    data: JSON.stringify ( res ),
                    status: res?.status
                } ) ;
                await context.commit ( `resetUserInfo` ) ;
                context.commit ( `setFinish`, true ) ;
                context.commit ( `updateIsRequesting`, false ) ;
                BigBigWork.emit ( `onUserInfoFailed` ) ;
                return Promise.resolve ( false ) ;
            } ;
            try {
                if ( context.state.isRequesting ) {
                    return new Promise ( resolve => {
                        context.commit ( `updateTodoFn`, { add: true, todoFn: resolve } ) ;
                    } ) ;
                }
                if ( !context.getters.userReady && !context.state.isRequesting ) {
                    context.commit ( `updateIsRequesting`, true ) ;
                }
                if ( context.getters.userReady && !isForce ) return Promise.resolve ( true ) ;
                const token = await getTokenCrossDomain () ;
                if ( context.rootState.token ) {
                    context.commit ( `setToken`, token, { root: true } ) ;
                }
                if ( !token ) {
                    recordLogOut ( {
                        token,
                        uid: context.state.id,
                        apiUrl: `userInfo没有取得token`,
                        apiRes: { context: { token: context.rootState.token } }
                    } ) ;
                    return await onFailed () ;
                }
                const res = await axios ( {
                    url: ServerPService + `/user/info`,
                    params: {
                        token,
                        _t: new Date ().getTime ()
                    }
                } )
                context.commit ( `setFinish`, true ) ;
                context.commit ( `updateIsRequesting`, false ) ;
                if ( res.status === 200 && res.data.userInfo ) {
                    const data = res.data ;
                    context.commit ( `setUserInfo`, data ) ;
                    if ( window.location.hostname.match ( `bigbigwork.com` ) ) {
                        // 如果是大作，则设置根域名
                        Cookies.set ( `jhk-personal`, token, { domain: getDomain (), path: `/`, expires: 7 } ) ;
                        Cookies.set ( `dashisousuo`, token, { domain: getDomain (), path: `/`, samesite: `none`, expires: 7, secure: true } ) ;
                    } else {
                        Cookies.set ( `jhk-personal`, token, { domain: getDomain (), path: `/`, expires: 7 } ) ;
                    }
                    clearToDoFn () ;
                    context.commit ( `setToken`, token, { root: true } ) ;
                    BigBigWork.emit ( `onUserInfoReady` ) ;
                    return Promise.resolve ( true ) ;
                } else {
                    recordLogOut ( {
                        token,
                        uid: context.state.id,
                        apiUrl: `/user/info`,
                        apiRes: res
                    } ) ;
                    // 登录失败就重置用户信息和token
                    return await onFailed ( res ) ;
                }
            } catch ( e ) {
                context.commit ( `setFinish`, true ) ;
                context.commit ( `updateIsRequesting`, false ) ;
                const token = await getTokenCrossDomain () ;
                recordLogOut ( {
                    token,
                    uid: context.state.id,
                    apiUrl: `/user/info`,
                    apiRes: e.message
                } ) ;
                return await onFailed ( { data: e, status: 500 } ) ;
            }
        },
        /** 获取用户团队列表 */
        FETCH_USER_TEAM: async ( context, isForce:boolean = false ) =>{
            const clearToDoFn = () => {
                if ( context.state.teamTodoFn.length ) {
                    context.state.teamTodoFn.forEach ( fn => fn ( false ) ) ;
                    context.commit ( `updateTeamTodoFn`, {} ) ;
                }
            } ;
            // updateReqTeamStatus
            if (context.state.isGetedTeamList) return context.state.teamsList;
            if (context.state.isReqTeamList) {
                return new Promise(resolve => {
                    context.commit('updateTeamTodoFn',{add: true, teamTodoFn: resolve})
                })
            }
            context.commit('updateReqTeamStatus', {path:'isReqTeamList',data: true})
            const token = await getTokenCrossDomain () ;
            try{
                const res = await axios ( {
                    url: ServerTeam + `/enterprise/getList`,
                    params: {
                        token: token
                    }
                } ) ;
                if(res.status === 200 && res.data.code === 200) {
                    context.commit ( `setUserTeam`, res.data.data) ;
                    context.commit('updateReqTeamStatus', {path:'isGetedTeamList',data: true})
                    context.commit('updateReqTeamStatus', {path:'isReqTeamList',data: false})
                    clearToDoFn();
                    return Promise.resolve ( true ) ;
                }else {
                    await clearToDoFn () ;
                    return Promise.resolve ( false ) ;
                }
            } catch (e) {
                await clearToDoFn () ;
                return Promise.resolve ( false ) ;
            }
        },
        /** 从服务器检查用户唯一性 */
        FETCH_CHECK_BROWSER_UNIQUE: async ( context, data ) => {
            try {
                // 拉新统计参数
                let invite_login_page = `` ;
                let invite_code = `` ;
                const inviteLoginParams = Cookies.get ( `inviteLoginParams` ) ;
                if ( inviteLoginParams ) {
                    const params = JSON.parse ( decodeURIComponent ( inviteLoginParams ) ) ;
                    invite_login_page = params.invite_login_page ;
                    invite_code = params.invite_code ;
                }
                // checkMemory 是否需要对比内存中的token
                const { source = `pc_new`, checkMemory = true } = data || {} ;
                // 搜索图片集登录统计
                const tracert = Cookies.get ( `jhktracert` ) ? Cookies.get ( `jhktracert` ) : `` ;
                const jsonDate = tracert && tracert !== `undefined` ? JSON.parse ( decodeURIComponent ( tracert ) ) : `` ;
                let utmData = {
                    source: ``,
                    medium: ``,
                    campaign: ``,
                    content: ``,
                    term: ``,
                    url: ``
                } ;
                // if ( jsonDate && jsonDate.url && jsonDate.url.includes ( `searchboards` ) ) {
                if ( jsonDate && jsonDate.url ) {
                    utmData = jsonDate ;
                } else {
                    utmData = {
                        source: ``,
                        medium: ``,
                        campaign: ``,
                        content: ``,
                        term: ``,
                        url: ``
                    } ;
                }
                // 超过过期时间了，重新写入时间并检查
                Cookies.set ( context.state.cookieName, new Date ().getTime () + context.state.checkTimeMs + `` ) ;
                const token = await getTokenCrossDomain () ;
                if ( !token ) {
                    recordLogOut ( {
                        token,
                        uid: context.state.id,
                        apiUrl: `checkBrowserUnique没有取得token`,
                        apiRes: { context: { token: context.rootState.token, source } }
                    } ) ;
                    await context.dispatch ( `STATS_LOGIN_OUT`, {
                        cookie_token: token,
                        memory_token: context.rootState.token
                    } ) ;
                    await context.commit ( `resetUserInfo` ) ;
                    return { status: 200, data: { status: 500 } } ;
                }
                const res = await axios ( {
                    method: `POST`,
                    url: ServerPService + `/checkBrowserUnique.htm`,
                    params: {
                        user_token: token,
                        source: source,
                        utm_source: utmData.source,
                        utm_medium: utmData.medium,
                        utm_campaign: utmData.campaign,
                        utm_content: utmData.content,
                        utm_term: utmData.term,
                        utm_url: utmData.url,
                        _t: new Date ().getTime (),
                        invite_login_page: encodeURIComponent ( invite_login_page ),
                        invite_code
                    }
                } ) ;
                console.log ( `调用了checkBrowserUnique接口` ) ;
                await context.dispatch ( `handleIsSuct`, { token } ) ;
                if ( res.data.status === `200` ) {
                    // 轮询正常
                    Cookies.remove ( `inviteLoginParams`, { domain: getDomain (), path: `/` } ) ;
                    // 如果token和当前不符，重新获取用户数据
                    if ( context.rootState.token !== `1` && token !== context.rootState.token && checkMemory === true ) {
                        await context.dispatch ( `FETCH_USER_INFO`, true ) ;
                        BigBigWork.emit ( `onUserChange` ) ;
                    }
                    if ( window.location.hostname.match ( `bigbigwork.com` ) ) {
                        // 如果是大作，则设置根域名
                        Cookies.set ( `jhk-personal`, token, { domain: getDomain (), expires: 7 } ) ;
                        Cookies.set ( `dashisousuo`, token, { domain: getDomain (), samesite: `none`, expires: 7, secure: true } ) ;
                    } else {
                        Cookies.set ( `jhk-personal`, token, { domain: getDomain (), expires: 7 } ) ;
                    }
                    context.commit ( `setToken`, token, { root: true } ) ;
                    Cookies.set ( `jhktracert`, JSON.stringify ( {} ), {
                        expires: -1,
                        domain: `.bigbigwork.com`
                    } ) ;
                } else {
                    // 轮询失败
                    const token = await getTokenCrossDomain () ;
                    await context.dispatch ( `STATS_LOGIN_OUT`, {
                        cookie_token: token,
                        memory_token: context.rootState.token,
                        data: JSON.stringify ( res.data ),
                        status: res.status
                    } ) ;
                    recordLogOut ( {
                        token,
                        uid: context.state.id,
                        apiUrl: `/checkBrowserUnique.htm`,
                        apiRes: { ...res.data }
                    } ) ;
                    await context.commit ( `resetUserInfo` ) ;
                }
                return res ;
            } catch ( e ) {
                const token = await getTokenCrossDomain () ;
                recordLogOut ( {
                    token,
                    uid: context.state.id,
                    apiUrl: `/checkBrowserUnique.htm`,
                    apiRes: e.message
                } ) ;
                return { status: 500, data: e.message } ;
            }
        },
        /** 检查唯一性
         * @param context vuex上下文
         * @param manualCheck 手动调用逻辑 通过则刷新当前页,否则回首页
         * @return  检查结果
         */
        checkUnique: async ( context, { source = `pc_new`, force = false } ):Promise<Boolean> => {
            try {
                const token = await getTokenCrossDomain () ;
                if ( !token ) {
                    const token = await getTokenCrossDomain () ;
                    recordLogOut ( {
                        token,
                        uid: context.state.id,
                        apiUrl: `checkUnique没有取得token`,
                        apiRes: ``
                    } ) ;
                    context.state.isShowError = true ;
                    return Promise.resolve ( false ) ;
                }
                // 已经弹了就不弹了
                if ( !context.state.isShowError ) {
                    const ts = Cookies.get ( context.state.cookieName ) ;
                    // 浏览器共用cookie，为空或是未满5分钟，不调用
                    const expiredTime = !!ts && parseInt ( ts ) ;
                    if ( new Date ().getTime () >= expiredTime || force ) {
                        // 超过5分钟了，重新写入时间并检查
                        Cookies.set ( context.state.cookieName, new Date ().getTime () + context.state.checkTimeMs + `` ) ;
                        console.log ( `时间到了，检查` ) ;
                        const res = await context.dispatch ( `FETCH_CHECK_BROWSER_UNIQUE`, { source } ) ;
                        // 网络错误，放行
                        if ( res.status !== 200 ) {
                            return Promise.resolve ( true ) ;
                        }
                        // 不是唯一登录，返回错误；
                        if ( res.data.status === `500` ) {
                            const browser = Cookies.get ( `browser` ) ;
                            console.log ( `唯一用户验证失败；User：` + context.state.id + `；browser:` + browser ) ;
                            console.log ( `res:` + res ) ;
                            context.state.isShowError = true ;
                            return Promise.resolve ( false ) ;
                        } else {
                            return Promise.resolve ( true ) ;
                        }
                    } else {
                        console.log ( `别的窗口检查过了，不检查了` ) ;
                        return Promise.resolve ( true ) ;
                    }
                }
            } catch ( e ) {
                return Promise.resolve ( true ) ;
            }
        },
        /** 判断是否添加注册 或者 登录统计
         */
        handleIsSuct: async ( context, data ) => {
            const token_time = Number ( decodeBase64 ( data.token ).split ( `,` )[3] ) ;
            if ( Cookies.get ( `isCallStatLogin` ) ) {
                console.log ( `Cookies.get( \`isCallStatLogin\` )`, Cookies.get ( `isCallStatLogin` ) ) ;
                return ;
            }
            return axios ( {
                method: `GET`,
                url: ServerWww + `/system/time`
            } ).then ( ( res ) => {
                console.log ( res, `测试每一分钟报错一次` ) ;
                var isWwwPage = window.location.href.includes ( `bigbigwork.com` ) ;
                var pageName = isWwwPage ? `.bigbigwork.com` : `.${process.env.PINLITE_DOMAIN}` ;
                if ( res.data - token_time >= 300000 ) {
                    console.log ( `无需统计`, res.data ) ;
                } else {
                    if ( token_time - context.state.createTime >= 60000 ) {
                        console.log ( `统计登录` ) ;
                        _hmt.push ( [`_trackEvent`, `用户session`, `登录`, `${context.state.isUserPay}`] ) ;
                    } else {
                        console.log ( `统计注册` ) ;
                        _hmt.push ( [`_trackEvent`, `用户session`, `注册`, `${context.state.isUserPay}`] ) ;
                    }
                    // 设置5分钟cookie 防止刷新重复调用接口
                    // @ts-ignore
                    Cookies.set ( `isCallStatLogin`, 1, {
                        expires: 1 / 24 / 12,
                        domain: pageName
                    } ) ;
                }
            } ) ;
        }
    },

    getters: {
        /** 是否已经获取到了用户信息 */
        userReady: ( state ):boolean => {
            return state.id > -1 ;
        },
        /** 是否是PinliteVIP */
        isPinliteVip: ( state ):boolean => {
            return state.vipDays > 0 || state.pinliteDays > 0 ;
        },
        /** 是否是大作VIP */
        isVip: ( state ):boolean => {
            return !!state.vipDays ;
        },
        initCheckLogin: ( state ) => async () => {
            // 初次轮询，写入时间，浏览器共用
            Cookies.set ( state.cookieName, new Date ().getTime () + state.checkTimeMs + `` ) ;
        },
        isShowGuide: ( state ):boolean => {
            return state.createTime > 1606752000000 ;
            // return false
        },
        userA: ( state ):boolean => {
            return state.id > -1 && state.id % 2 !== 0 ;
        },
        userB: ( state ):boolean => {
            return state.id > -1 && state.id % 2 === 0 ;
        },
        user4: ( state ):boolean => {
            return state.id > -1 && state.id % 4 === 0 ;
        }
    }
} ;

export const CreateUserInfoStore = ( store:Store<any> ) => {
    if ( store.hasModule ( `UserInfo` ) ) return ;
    /** 在store上注册模块 */
    store.registerModule ( `UserInfo`, UserInfo ) ;
} ;
